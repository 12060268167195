import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  max-width: 600px;
  height: calc(100vh - 8rem);
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`
