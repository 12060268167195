import styled from "styled-components";

import ActionButton from "../ActionButton";
import FormCheckbox from "../FormCheckbox";
import FormInput from "../FormInput";
import Link from "../Link";

export const LoginForm = styled.div``;

export const TextField = styled(FormInput)``;
export const CheckboxField = styled(FormCheckbox)``;

export const Image = styled.img`
  display: block;
  margin: auto;
  width: 100%;
  height: 33.5rem;
  object-fit: cover;
  margin-bottom: 2rem;
`;

export const Title = styled.div`
  ${({ theme }) => theme.textStyles.titleH1}
  text-align: center;
  margin-bottom: 2rem;
`;

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.text};
  text-align: center;
  padding: 12px;
`;

export const Fields = styled.div`
  margin-bottom: 1.5rem;
`;
export const SubmitButton = styled(ActionButton)`
  display: block;
  margin: auto;
`;

export const ForgottenPasswordLink = styled(Link)`
  display: block;
  ${({ theme }) => theme.textStyles.text}
  text-align: right;
  margin-bottom: 4rem;
`;
