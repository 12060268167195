import { Saga } from "redux-saga";
import { ActionPattern, all, call, put, takeLatest } from "redux-saga/effects";

import { actions } from "..";
import { MutationNdaAcceptAgreementArgs } from "../../generated/graphql";
import { ServiceRequestAction } from "../../helpers/ReduxHelpers";
import ApiSagas from "../api/sagas";
import { ApiResponse } from "../api/types/state";

import { setSignature } from "./services";
import ndaAcceptAgreement from "../../graphql/services/user/mutations/ndaAcceptAgreement";

export default class DisclosureSagas {
  static *init() {}

  static *setSignature({
    payload,
  }: ServiceRequestAction<MutationNdaAcceptAgreementArgs>) {
    const result: ApiResponse<typeof setSignature> = yield call(
      ApiSagas.mutate,
      ndaAcceptAgreement,
      payload
    );

    if (!result.errors && result.data) {
      yield put(actions.disclosure.setSignatureSuccess(true));
    } else {
      yield put(actions.disclosure.setSignatureError(result.errors));
    }
  }

  static *loop() {
    yield all([
      takeLatest(
        actions.disclosure.setSignatureRequest as ActionPattern,
        this.setSignature as Saga
      ),
    ]);
  }
}
