import gql from 'graphql-tag'

const BrandFragment = gql`
  fragment BrandFragment on ConfigurationBrand {
    name
    value
  }
`

export default BrandFragment
