import React, { FC, FormEvent, InputHTMLAttributes } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type FormCheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  className?: string
  label?: string
  error?: string
  onChange?: (name: string, value: string | boolean) => void
}

const FormCheckbox: FC<FormCheckboxProps> = (props) => {
  const { className, name, label, id, error, onChange, ...checkboxProps } = props

  const onChangeHandler = (event: FormEvent<HTMLInputElement>) => {
    onChange?.(name, !!event.currentTarget.checked)
  }

  return (
    <SC.Container className={cx('FormCheckbox', className)}>
      <SC.Label>
        <SC.Checkbox
          id={id}
          {...checkboxProps}
          type="checkbox"
          name={name}
          $isError={!!error}
          checked={!!checkboxProps.value}
          onChange={onChangeHandler}
        />
        {label}
      </SC.Label>
      {error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
    </SC.Container>
  )
}

export default FormCheckbox
