import gql from 'graphql-tag'

export default gql`
  fragment SaleEventFragment on SaleEvent {
    addressExtra
    createdAt
    disclaimerEmployeeFR
    disclaimerEmployeeEN
    disclaimerGuestFR
    disclaimerGuestEN
    endAt
    id
    maxTotalCapacity
    name
    registrationClosingAt
    startAt
    updatedAt
    useDisclaimer
  }
`
