import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import NotFoundTemplate, { NotFoundTemplateProps } from '../templates/NotFound'
import { router, routesPath } from '../router'
import { selectors } from '../redux'

function Page404() {
  const locale = useSelector(selectors.configuration.locale)
  const defaultLocale = useSelector(selectors.configuration.defaultLocale)
  const notFoundProps: NotFoundTemplateProps = useMemo(
    () => ({
      title: 'Page not found',
      cta: {
        text: 'Return to site',
        link: {
          link: router(routesPath.login, { locale: locale ?? defaultLocale }),
        },
      },
    }),
    [locale, defaultLocale]
  )

  return <NotFoundTemplate {...notFoundProps} />
}

export default Page404
