import styled from 'styled-components'

import Icon from '../../components/Icon'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  text-align: center;

  flex-grow: 1;
  padding: 2rem;
  padding-bottom: 10rem;
`

export const Title = styled.p`
  ${({ theme }) => theme.textStyles.titleH1}
`
export const Text = styled.p`
  ${({ theme }) => theme.textStyles.text};
`
export const Spin = styled(Icon)`
  width: 2.5rem;
  height: 2.5rem;
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
  animation: rotate 1.3s linear infinite;
`
