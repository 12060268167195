import {
  all,
  call,
  fork,
  put,
  SagaReturnType,
  select,
  takeLeading,
} from "redux-saga/effects";

import { actions, selectors } from "..";
import AuthSagas from "../auth/sagas";
import ConfigurationSagas from "../configuration/sagas";
import JourneySagas from "../disclosure/sagas";

export default class AppSagas {
  static *init() {
    const isInit: SagaReturnType<typeof selectors.app.isInit> = yield select(
      selectors.app.isInit
    );
    yield call(AuthSagas.onInit); // must be first auth call to refresh token if needed

    const pathname = window.location.pathname;
    const locale = pathname.match(/^\/([\w]{2})\//)?.[1];

    yield call(ConfigurationSagas.init, locale);
    yield call(JourneySagas.init);
    yield fork(AppSagas.loadInitialData); // can be forked

    if (!isInit) {
      yield put(actions.app.setIsInit({ isInit: true }));
    }
  }

  static *loadInitialData() {
    yield all([]);
  }

  static *loop() {
    yield all([takeLeading("persist/REHYDRATE", this.init)]);
  }
}
