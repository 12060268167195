import gql from "graphql-tag";

import { CheckTokenQuery } from "../../../../generated/graphql";

const ndaCheckResetToken = {
  query: gql`
    query ndaCheckResetToken($resetToken: String!, $email: String!) {
      ndaCheckResetToken(resetToken: $resetToken, email: $email)
    }
  `,
  transformer: (response: CheckTokenQuery) => {
    return response.checkToken;
  },
};

export default ndaCheckResetToken;
