import styled, { css } from 'styled-components'

import Icon from '../Icon'

export const Container = styled.div`
  width: 100%;
  text-align: center;
`

export const CanvasContainer = styled.div<{ isError?: boolean }>`
  position: relative;
  width: 100%;
  line-height: 0;

  ${({ isError, theme }) =>
    isError &&
    css`
      border: 1px solid ${theme.palette.colors.red};
    `}
`

export const IndicationText = styled.p`
  position: absolute;
  left: 2rem;
  bottom: 1.5rem;
  pointer-events: none;
  line-height: normal;
  letter-spacing: 0.05em;
  color: #808080;
  ${({ theme }) => theme.textStyles.text}
`

export const RefreshIcon = styled(Icon)`
  margin-top: 25px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`
