import { RouteType } from "../types/routes";
import DisclosurePage from "../pages/Disclosure";
import LoginPage from "../pages/Login";
import GuestGuard from "../components/GuestGuard";
import AuthGuard from "../components/AuthGuard";
import ConfirmationPage from "../pages/Confirmation";
import ResetPasswordPage from "../pages/ResetPassword";
import NoNDAPage from "../pages/NoNDA";
import ConfigurePasswordPage from "../pages/ConfigurePassword";

import { routesPath } from "./index";

const publicRoutes: RouteType = {
  path: "",
  component: null,
  guard: GuestGuard,
  children: [
    {
      path: routesPath.login,
      name: "Login",
      component: LoginPage,
    },
    {
      path: routesPath.noNDA,
      name: "NoNDA",
      component: NoNDAPage,
    },
    {
      path: routesPath.resetPassword,
      name: "ResetPassword",
      component: ResetPasswordPage,
    },
    {
      path: routesPath.configurePassword,
      name: "ConfigurePassword",
      component: ConfigurePasswordPage,
    },
  ],
};

const privateRoutes: RouteType = {
  path: "",
  component: null,
  guard: AuthGuard,
  children: [
    {
      path: routesPath.disclosure,
      name: "Disclosure",
      component: DisclosurePage,
    },
    {
      path: routesPath.confirmation,
      name: "Confirmation",
      component: ConfirmationPage,
    },
  ],
};

export const defaultLayoutRoutes = [publicRoutes, privateRoutes];
