import styled, { css } from "styled-components";

import Icon from "../Icon";
import LinkC from "../Link";

export const Link = styled(LinkC)`
  display: inline-flex;
  text-decoration: none;
`;

export const Button = styled.button<{
  disabled?: boolean;
  $outlined?: boolean;
  $isLoading?: boolean;
  $secondary?: "secondary";
}>`
  padding: 1rem 1.1rem;
  border-radius: 0.25rem;
  color: black;
  border: 1px solid black;
  background-color: white;
  cursor: pointer;
  ${(props) => props.theme.textStyles.cta}

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      pointer-events: none;
    `}

    ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
  &:hover {
    background-color: black;
    color: white;
  }
  ${({ $secondary }) =>
    $secondary === "secondary" &&
    css`
      color: white;
      border: 1px solid black;
      background-color: black;
      &:hover {
        background-color: white;
        color: black;
      }
    `}

  padding-top: 1.4rem;
`;

export const IconIn = styled(Icon)<{ iconSide?: string }>`
  width: 1.8rem;
  height: 1.8rem;
  margin-left: ${(props) => (props.iconSide === "right" ? "1rem" : "0")};
  margin-right: ${(props) => (props.iconSide === "left" ? "1rem" : "0")};
`;
