import React, { FC, useState } from "react";
import cx from "classnames";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import ReactSignatureCanvas from "react-signature-canvas";

import { getValidationSchema, renderField } from "../../helpers/FormHelpers";
import { FormFieldConfig, FormFieldProps } from "../../types/form";
import { ActionButtonProps } from "../ActionButton";
import SignatureCanvas from "../SignatureCanvas";
import FormErrors, { FormErrorsProps } from "../FormErrors";

import * as SC from "./styled";

export type DisclosureFormValues = {
  terms: boolean;
};

export type DisclosureFormProps = {
  className?: string;
  title?: string;
  text?: string;
  fieldProps?: {
    terms: FormFieldProps;
  };
  errorTexts?: {
    terms?: string;
  };
  submitButton: ActionButtonProps;
  initialValues: DisclosureFormValues;
  errors?: FormErrorsProps["errors"];
  onSubmit?: (
    values: DisclosureFormValues,
    formikHelpers: FormikHelpers<DisclosureFormValues>,
    signature: string
  ) => void;
};

const DisclosureForm: FC<DisclosureFormProps> = (props) => {
  const {
    className,
    title,
    text,
    errorTexts,
    fieldProps,
    initialValues,
    submitButton,
    onSubmit,
    errors,
  } = props;

  const [
    signatureCanvas,
    setSignatureCanvas,
  ] = useState<ReactSignatureCanvas | null>(null);
  const [isSignatureError, setIsSignatureError] = useState<boolean>(false);

  const fieldCheckbox: FormFieldConfig = {
    name: "terms",
    Component: SC.CheckboxField,
    validation: Yup.bool().oneOf([true], errorTexts?.terms),
  };

  const fields: FormFieldConfig[] = [fieldCheckbox];

  const validationSchema = getValidationSchema(fields);

  const handleSubmit = (
    values: DisclosureFormValues,
    formikHelpers: FormikHelpers<DisclosureFormValues>
  ) => {
    if (signatureCanvas && !signatureCanvas.isEmpty()) {
      onSubmit?.(values, formikHelpers, signatureCanvas.toDataURL());
    } else {
      setIsSignatureError(true);
    }
  };

  return (
    <SC.DisclosureForm className={cx("LoginForm", className)}>
      {title && <SC.Text>{title}</SC.Text>}
      {text && <SC.Text>{text}</SC.Text>}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(formikProps) => (
          <Form noValidate>
            <SC.Fields>
              {renderField(fieldCheckbox, formikProps, fieldProps)}
            </SC.Fields>
            <SignatureCanvas
              onRef={(signatureCanvasRef) =>
                setSignatureCanvas(signatureCanvasRef)
              }
              isError={isSignatureError}
            />
            {errors && <FormErrors errors={errors} />}
            <SC.SubmitButton {...submitButton} type="submit" />
          </Form>
        )}
      </Formik>
    </SC.DisclosureForm>
  );
};

export default DisclosureForm;
