import React, { FC } from "react";

import { ActionButtonProps } from "../../components/ActionButton";
import Layout, { LayoutProps } from "../../layouts/Layout";

import * as SC from "./styled";

export type ConfirmationTemplateProps = {
  className?: string;
  layoutProps?: LayoutProps;
  title?: string;
  name?: string;
  text?: string;
  backButton?: ActionButtonProps;
  cancelButton?: ActionButtonProps;
};

const ConfirmationTemplate: FC<ConfirmationTemplateProps> = (props) => {
  const { layoutProps, title, name, text, backButton, cancelButton } = props;

  return (
    <Layout {...layoutProps}>
      <SC.Content>
        <SC.Title>
          {title}
          <br />
          {name}
        </SC.Title>
        <SC.Text>{text}</SC.Text>
        <SC.Actions>
          {backButton && <SC.Cta {...backButton} />}
          {cancelButton && <SC.Cta {...cancelButton} />}
        </SC.Actions>
      </SC.Content>
    </Layout>
  );
};

export default ConfirmationTemplate;
