import gql from 'graphql-tag'

export default gql`
  fragment SaleEventSlotFragment on SaleEventSlot {
    closingHour
    createdAt
    label
    id
    isVip
    maxCapacity
    maxCapacityGuest
    openingHour
    updatedAt
    availableForGuest
    availableForEmployee
    slotLefts
    matchingRule {
      id
      maxCapacityGuest
    }
  }
`
