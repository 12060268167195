import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Header, { HeaderProps } from "../components/Header";
import { actions, selectors } from "../redux";

export const useHeader = ({ location }: { location: any }): React.ReactNode => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const locales = useSelector(selectors.configuration.locales);
  const currentLocale = useSelector(selectors.configuration.locale);

  const isConnected = useSelector(selectors.auth.isConnected);
  const isGuest = useSelector(selectors.auth.isGuest);

  const header: HeaderProps = useMemo(
    () => ({
      switchLocale: {
        locales: locales
          ? locales.map((locale) => ({
              name: locale.label,
              locale: locale.iso,
              isSelected: locale.iso === currentLocale,
              link: {
                href: location?.pathname?.replace(
                  currentLocale ?? "",
                  locale.iso
                ),
                onClick: () => {
                  setIsVisible(false);
                  dispatch(
                    actions.configuration.setLocale({ locale: locale.iso })
                  );
                  dispatch(
                    actions.configuration.changeLocale({ locale: locale.iso })
                  );
                },
              },
            }))
          : [],
        openSwitchHandler: () => setIsVisible(!isVisible),
        isVisible,
      },
      logout:
        isConnected && !isGuest
          ? {
              label: t("logOut"),
              onClick: () => {
                dispatch(actions.auth.authLogoutRequest(undefined));
              },
            }
          : undefined,
    }),
    [
      locales,
      isVisible,
      isConnected,
      t,
      currentLocale,
      location?.pathname,
      dispatch,
      isGuest,
    ]
  );

  return <Header {...header} />;
};
