import styled from "styled-components";

import Logo from "../../components/Logo";

export const Wrapper = styled.div`
  width: 375px;
  margin: auto;

  @media screen and (max-width: 375px) {
    width: 100%;
    padding: 2rem;
  }
`;

export const SLogo = styled(Logo)`
  display: block;
  margin: 2rem auto;
`;
