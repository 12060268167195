import { all, fork } from "redux-saga/effects";

import ApiSagas from "./api/sagas";
import AppSagas from "./app/sagas";
import AuthSagas from "./auth/sagas";
import ConfigurationSagas from "./configuration/sagas";
import DisclosureSagas from "./disclosure/sagas";

function* loop() {
  yield all([
    ApiSagas.loop(),
    AppSagas.loop(),
    AuthSagas.loop(),
    ConfigurationSagas.loop(),
    DisclosureSagas.loop(),
  ]);
}

export default function* rootSaga() {
  yield fork(loop);
}
