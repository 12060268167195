import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { PreloadTemplateProps } from '../templates/Preload'
import Header from '../components/Header'

export const usePreload = () => {
  const { t } = useTranslation()

  const preloadProps: PreloadTemplateProps = useMemo(
    () => ({
      layoutProps: {
        header: <Header />,
      },
      title: t('preloadTitle'),
      text: t('preloadText'),
    }),
    [t]
  )

  return preloadProps
}
