import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { actions, selectors } from "../redux";
import { router, routesPath } from "../router";
import { ChangePasswordTemplateProps } from "../templates/ChangePassword";

import { useHeader } from "./useHeader";
import { useSagaTakeEvery } from "./useSaga";

const useConfigurePassword = ({
  token,
  email,
  history,
  location,
}: {
  token: string | null;
  email: string | null;
  history: any;
  location: any;
}): { changePasswordTemplate: ChangePasswordTemplateProps } => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pending, errors, success } = useSelector(
    selectors.auth.resetPassword
  );
  const locale = useSelector(selectors.configuration.locale);
  const header = useHeader({ location });

  useEffect(() => {
    if (token) {
      dispatch(
        actions.auth.checkTokenRequest({
          resetToken: token,
          email: email,
        })
      );
    }
  }, [dispatch, email, token]);

  useSagaTakeEvery(
    actions.auth.checkTokenSuccess,
    useCallback(
      (action) => {
        if (action.payload === false) {
          history.push(router(routesPath.login, { locale: locale ?? "" }));
        }
      },
      [history, locale]
    )
  );

  const changePasswordTemplate: ChangePasswordTemplateProps = useMemo(
    () => ({
      layoutProps: {
        header,
      },
      changePasswordForm: {
        title: t("configurePasswordFormTitle"),
        text: t("configurePasswordFormText"),
        ...(success && {
          successMessage: t("configurePasswordSuccessMessage"),
        }),
        initialValues: {
          password: "",
        },
        fieldProps: {
          password: {
            label: t("configurePasswordFormLabel"),
          },
        },
        submitButton: {
          isLoading: pending,
          text: t("configurePasswordFormButton"),
        },
        errors,
        onSubmit: (values) => {
          dispatch(
            actions.auth.resetPasswordRequest({
              input: {
                email,
                password: values.password,
                passwordConfirmation: values.password,
                resetToken: token,
              },
            })
          );
        },
      },
    }),
    [header, t, success, pending, errors, dispatch, email, token]
  );

  return {
    changePasswordTemplate,
  };
};

export default useConfigurePassword;
