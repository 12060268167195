import React, { FC, FormEvent, InputHTMLAttributes, useState } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type FormInputProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  className?: string
  error?: string
  errors?: { message?: string }[]
  label?: string
  onChange?: (name: string, value: string) => void
}

const FormInput: FC<FormInputProps> = (props) => {
  const { className, name, id, label, error, placeholder, onChange, ...inputProps } = props
  const [isActive, setActive] = useState(true)

  const onChangeHandler = (event: FormEvent<HTMLInputElement>) => {
    onChange?.(name, event.currentTarget.value)
  }

  return (
    <SC.Container>
      <SC.LabelText>{label}</SC.LabelText>
      <SC.Label
        className={cx('FormInput', className)}
        $isError={!!error}
        $isActive={isActive}
        $isDisabled={inputProps.disabled}
      >
        <SC.Input
          {...inputProps}
          {...(id && { id })}
          name={name}
          type={inputProps.type ?? 'text'}
          placeholder={placeholder}
          $isError={!!error}
          $isActive={isActive}
          $isDisabled={inputProps.disabled}
          onFocus={() => setActive(true)}
          onChange={onChangeHandler}
          onBlur={(e) => {
            inputProps?.onBlur?.(e)
            setActive(e.currentTarget.value !== '')
          }}
          onLoad={(e) => {
            setActive(e.currentTarget.value !== '')
          }}
        />
      </SC.Label>
      {error && <SC.ErrorMessage>{error}</SC.ErrorMessage>}
    </SC.Container>
  )
}

export default FormInput
