import gql from "graphql-tag";

import AgreementFragment from "../../../fragments/AgreementFragment";

const ndaMe = {
  query: gql`
    query ndaMe {
      ndaMe {
        id
        locale
        firstName
        lastName
        fullName
        email
        jwtToken
        lastLoginAt
        currentAgreement {
          ...AgreementFragment
        }
        agreements {
          ...AgreementFragment
        }
        createdAt
      }
    }
    ${AgreementFragment}
  `,
  transformer: (response: any) => {
    return response.ndaMe;
  },
};

export default ndaMe;
