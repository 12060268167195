import React from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";

import DisclosureTemplate from "../../templates/Disclosure";
import { useDisclosure } from "../../hooks/useDisclosure";

const DisclosurePage: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const history = useHistory();
  const { disclosureTemplate } = useDisclosure({ location, history });
  return <DisclosureTemplate {...disclosureTemplate} />;
};

export default DisclosurePage;
