import gql from 'graphql-tag'

import SaleEventSlotFragment from './SaleEventSlotFragment'

export default gql`
  fragment SaleEmployeeEventFragment on SaleEmployeeEvent {
    id
    invitedAt
    signature
    checkInAt
    acceptDisclaimerAt
    slots {
      ...SaleEventSlotFragment
    }
    guestSlots {
      ...SaleEventSlotFragment
    }
    slot {
      ...SaleEventSlotFragment
    }
    availableSlots {
      ...SaleEventSlotFragment
    }
  }
  ${SaleEventSlotFragment}
`
