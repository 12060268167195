import gql from 'graphql-tag'

const WordingFragment = gql`
  fragment WordingFragment on ConfigurationWording {
    key
    translation
  }
`

export default WordingFragment
