import gql from "graphql-tag";

import { NdaResetPasswordMutation } from "../../../../generated/graphql";

const ndaResetPassword = {
  mutation: gql`
    mutation ndaResetPassword(
      $email: String!
      $resetToken: String!
      $password: String!
      $passwordConfirmation: String!
    ) {
      ndaResetPassword(
        email: $email
        resetToken: $resetToken
        password: $password
        passwordConfirmation: $passwordConfirmation
      ) {
        id
        email
        jwtToken
      }
    }
  `,
  transformer: (response: NdaResetPasswordMutation) => {
    return response.ndaResetPassword;
  },
};

export default ndaResetPassword;
