import {
  ActionPattern,
  all,
  call,
  put,
  SagaReturnType,
  select,
  takeLatest,
} from "redux-saga/effects";
import { Saga } from "redux-saga";

import { actions, selectors } from "..";
import ApiSagas from "../api/sagas";
import { ApiResponse } from "../api/types/state";
import ndaCheckResetToken from "../../graphql/services/user/queries/ndaCheckResetToken";
import {
  getAuthCookie,
  removeAuthCookie,
  setAuthCookie,
} from "../../helpers/CookieHelpers";
import employeeByToken from "../../graphql/services/user/queries/employeeByToken";
import authLogout from "../../graphql/services/user/mutations/authLogout";
import { ServiceRequestAction } from "../../helpers/ReduxHelpers";
import {
  MutationAuthResetPasswordArgs,
  MutationNdaForgotPasswordArgs,
  QueryCheckTokenArgs,
} from "../../generated/graphql";
import ndaLogin from "../../graphql/services/user/mutations/ndaLogin";
import ndaForgotPassword from "../../graphql/services/user/mutations/ndaForgotPassword";
import ndaMe from "../../graphql/services/user/queries/ndaMe";
import ndaResetPassword from "../../graphql/services/user/mutations/ndaResetPassword";

export default class AuthSagas {
  static *onInit() {
    const cookie = getAuthCookie();

    if (cookie) {
      yield put(actions.auth.setImpersonate(cookie?.impersonate ?? false));
      yield put(actions.auth.setToken(cookie?.token));
      console.log(cookie);
      const user: ApiResponse<typeof ndaMe> = yield call(ApiSagas.query, ndaMe);

      if (!user.errors && user.data) {
        yield put(actions.auth.setUser(user.data));
      }
    } else {
      yield put(actions.auth.setToken(null));
    }
  }

  static *authByCredentials({
    payload,
  }: {
    payload: { email: string; password: string };
  }) {
    const result: ApiResponse<typeof ndaLogin> = yield call(
      ApiSagas.mutate,
      ndaLogin,
      payload
    );

    if (!result.errors && result.data) {
      setAuthCookie({ token: result.data.ndaLogin.jwtToken as string });
      yield put(actions.auth.setToken(result.data.ndaLogin.jwtToken as string));
      const user: ApiResponse<typeof ndaMe> = yield call(ApiSagas.query, ndaMe);
      if (!user.errors && user.data) {
        yield put(actions.auth.setUser(user.data));
        yield put(actions.auth.loginSuccess(result.data));
      } else {
        console.log(user.errors);
      }
    } else {
      yield put(actions.auth.loginError(result.errors));
    }
  }

  static *authByToken() {
    const isConnected: SagaReturnType<
      typeof selectors.auth.isConnected
    > = yield select(selectors.auth.isConnected);
    if (isConnected) {
      const rs: ApiResponse<typeof employeeByToken> = yield call(
        ApiSagas.query,
        employeeByToken
      );
      if (!rs?.errors) {
        yield put(actions.auth.setUser(rs?.data));
      } else {
        yield put(actions.auth.resetAuth());
      }
    }
  }

  static *authLogout() {
    const rs: ApiResponse<typeof authLogout> = yield call(
      ApiSagas.mutate,
      authLogout
    );
    if (!rs?.errors) {
      yield put(actions.auth.authLogoutSuccess(rs?.data));
      if (rs?.data.ndaInvalidateToken) yield put(actions.auth.resetAuth());
    } else {
      yield put(actions.auth.resetAuth());
      yield put(actions.auth.authLogoutError(rs.errors));
    }
  }

  static *forgotPassword({
    payload,
  }: ServiceRequestAction<MutationNdaForgotPasswordArgs>) {
    const result: ApiResponse<typeof ndaForgotPassword> = yield call(
      ApiSagas.mutate,
      ndaForgotPassword,
      payload
    );

    if (!result.errors) {
      yield put(actions.auth.forgotPasswordSuccess(result.data));
    } else {
      yield put(actions.auth.forgotPasswordError(true));
    }
  }

  static *resetPassword({
    payload,
  }: ServiceRequestAction<MutationAuthResetPasswordArgs>) {
    console.log("Auth reset password payload", payload);
    const result: ApiResponse<typeof ndaResetPassword> = yield call(
      ApiSagas.mutate,
      ndaResetPassword,
      payload?.input
    );

    if (!result.errors) {
      yield put(actions.auth.resetPasswordSuccess(true));
      yield put(actions.auth.setToken(result.data.jwtToken as string));
      const user: ApiResponse<typeof ndaMe> = yield call(ApiSagas.query, ndaMe);
      if (!user.errors && user.data) {
        yield put(actions.auth.setUser(user.data));
      }
    } else {
      yield put(actions.auth.resetPasswordError(result.errors));
    }
  }

  static *checkTokenRequest({
    payload,
  }: ServiceRequestAction<QueryCheckTokenArgs>) {
    const result: ApiResponse<typeof ndaCheckResetToken> = yield call(
      ApiSagas.query,
      ndaCheckResetToken,
      payload
    );
    if (!result.errors) {
      yield put(actions.auth.checkTokenSuccess(result.data));
    } else {
      yield put(actions.auth.checkTokenError(result.errors));
    }
  }

  static onResetAuth() {
    removeAuthCookie();
  }

  static *onSetToken() {
    const token: SagaReturnType<typeof selectors.auth.token> = yield select(
      selectors.auth.token
    );
    const impersonate: SagaReturnType<
      typeof selectors.auth.impersonate
    > = yield select(selectors.auth.impersonate);

    if (token) {
      setAuthCookie({ token, impersonate });
    }
  }

  static *loop() {
    yield all([
      takeLatest(
        actions.auth.loginRequest as ActionPattern,
        this.authByCredentials as Saga
      ),
      takeLatest(actions.auth.authLogoutRequest, this.authLogout),
      takeLatest(
        actions.auth.forgotPasswordRequest as ActionPattern,
        this.forgotPassword as Saga
      ),
      takeLatest(
        actions.auth.resetPasswordRequest as ActionPattern,
        this.resetPassword as Saga
      ),
      takeLatest(actions.auth.checkTokenRequest, this.checkTokenRequest),
      takeLatest(
        actions.auth.resetAuth as ActionPattern,
        this.onResetAuth as Saga
      ),
      takeLatest(
        actions.auth.setToken as ActionPattern,
        this.onSetToken as Saga
      ),
      takeLatest(
        actions.auth.getUser as ActionPattern,
        this.authByToken as Saga
      ),
    ]);
  }
}
