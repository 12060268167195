import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../store";
import {
  getServiceProps,
  getServiceReducers,
  ServiceProps,
} from "../../helpers/ReduxHelpers";
import { MutationNdaAcceptAgreementArgs } from "../../generated/graphql";

type DisclosureState = {
  setSignature: ServiceProps<boolean, MutationNdaAcceptAgreementArgs>;
};

//
// Initial state
//

const initialState: DisclosureState = {
  setSignature: getServiceProps(),
};

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: "disclosure",
  initialState,
  reducers: {
    ...getServiceReducers("setSignature"),
  },
});

export const { reducer, actions } = slice;

//
// Selectors
//

const root = (state: RootState) => state[slice.name];
const setSignature = (state: RootState) => root(state).setSignature;

export const selectors = {
  setSignature,
};
