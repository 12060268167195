import React, { FC, useEffect } from 'react'
import cx from 'classnames'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { FormFieldConfig, FormFieldProps } from '../../types/form'
import { ActionButtonProps } from '../ActionButton'
import FormMessages, { FormMessagesProps } from '../FormMessages'

import * as SC from './styled'

export type ResetPasswordFormValues = {
  email: string
}

export type PopinResetPasswordProps = {
  className?: string
  title: string
  text: string
  fieldProps: {
    email: FormFieldProps
  }
  initialValues: {
    email: string
  }
  errorTexts?: {
    required?: string
    email?: string
  }
  submitButton: ActionButtonProps
  isOpen: boolean
  onSubmit?: (
    values: ResetPasswordFormValues,
    formikHelpers: FormikHelpers<ResetPasswordFormValues>
  ) => void
  submitMessages?: FormMessagesProps
  onClose?: () => void
}

const PopinResetPassword: FC<PopinResetPasswordProps> = (props) => {
  const {
    className,
    text,
    title,
    initialValues,
    errorTexts,
    fieldProps,
    submitButton,
    isOpen,
    submitMessages,
    onSubmit,
    onClose,
  } = props

  const emailField: FormFieldConfig = {
    name: 'email',
    Component: SC.EmailField,
    type: 'email',
    validation: Yup.string().required(errorTexts?.required).email(errorTexts?.email),
    label: fieldProps.email.label,
  }

  const validationSchema = getValidationSchema([emailField])

  const handleSubmit = (
    values: ResetPasswordFormValues,
    formikHelpers: FormikHelpers<ResetPasswordFormValues>
  ) => {
    onSubmit?.(values, formikHelpers)
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'initial'
    }
  }, [isOpen])

  return (
    <SC.Popin className={cx('PopinResetPassword', className)} $isOpen={isOpen}>
      <SC.Close onClick={onClose} />
      <SC.Content>
        <SC.Title>{title}</SC.Title>
        <SC.Text>{text}</SC.Text>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formikProps) => (
            <Form noValidate>
              <SC.Fields>{renderField(emailField, formikProps, fieldProps)}</SC.Fields>
              {submitMessages && <FormMessages {...submitMessages} />}
              <SC.SubmitButton {...submitButton} type="submit" />
            </Form>
          )}
        </Formik>
      </SC.Content>
    </SC.Popin>
  )
}

export default PopinResetPassword
