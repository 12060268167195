import gql from "graphql-tag";

import { ServiceMutation } from "../../../../redux/api/types/state";

const ndaAcceptAgreement: ServiceMutation = {
  mutation: gql`
    mutation ndaAcceptAgreement($agreementId: ID!, $signatureBase64: String!) {
      ndaAcceptAgreement(
        agreementId: $agreementId
        signatureBase64: $signatureBase64
      )
    }
  `,
  transformer: (response: boolean) => {
    return response;
  },
};

export default ndaAcceptAgreement;
