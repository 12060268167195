import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { actions, selectors } from "../redux";
import { router, routesPath } from "../router";
import { ChangePasswordTemplateProps } from "../templates/ChangePassword";

import { useHeader } from "./useHeader";
import { useSagaTakeEvery } from "./useSaga";
import { FormikProps } from "formik";
import { ChangePasswordFormProps } from "../components/ChangePasswordForm";

const useResetPassword = ({
  token,
  history,
  location,
}: {
  token: string;
  history: any;
  location: any;
}): { changePasswordTemplate: ChangePasswordTemplateProps } => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pending, errors, success } = useSelector(
    selectors.auth.resetPassword
  );
  const locale = useSelector(selectors.configuration.locale);
  const header = useHeader({ location });
  const ref = useRef<FormikProps<ChangePasswordFormProps>>(null);

  useEffect(() => {
    if (success) {
      const timeOut = setTimeout(() => {
        clearTimeout(timeOut);
        history.push(router(routesPath.login, { locale: locale ?? "" }));
      }, 2000);
    }

    return () => {
      dispatch(actions.auth.resetPasswordReset(null));
    };
  }, [dispatch, history, locale, success]);

  useEffect(() => {
    if (token) {
      dispatch(
        actions.auth.checkTokenRequest({
          input: {
            token,
          },
        })
      );
    }
  }, [dispatch, token]);

  useSagaTakeEvery(
    actions.auth.checkTokenSuccess,
    useCallback(
      (action) => {
        if (action.payload === false) {
          history.push(router(routesPath.login, { locale: locale ?? "" }));
        }
      },
      [history, locale]
    )
  );

  const changePasswordTemplate: ChangePasswordTemplateProps = useMemo(
    () => ({
      layoutProps: {
        header,
      },
      changePasswordForm: {
        innerRef: ref,
        title: t("resetPasswordFormTitle"),
        text: t("resetPasswordFormText"),
        ...(success && { successMessage: t("resetPasswordSuccessMessage") }),
        initialValues: {
          password: "",
        },
        fieldProps: {
          password: {
            label: t("resetPasswordFormLabel"),
          },
        },
        submitButton: {
          isLoading: pending,
          text: t("resetPasswordFormButton"),
        },
        errors,
        onSubmit: (values) => {
          dispatch(
            actions.auth.resetPasswordRequest({
              input: {
                password: values.password,
                token,
              },
            })
          );
        },
      },
    }),
    [header, t, success, pending, errors, dispatch, token]
  );

  return {
    changePasswordTemplate,
  };
};

export default useResetPassword;
