import { generatePath } from "react-router-dom";

export const router = (
  route: string,
  options?: { [key: string]: string }
): string => {
  return generatePath(route, options);
};

export const routesPath = {
  configurePassword: "/:locale/changePassword",
  resetPassword: "/:locale/resetPassword/:token",
  disclosure: "/:locale/disclosure/:agreementId?",
  invitations: "/:locale/invitations/:agreementId?",
  confirmation: "/:locale/confirmation/:agreementId?",
  login: "/:locale/login/:agreementId?",
  noNDA: "/:locale/closing/:agreementId?",
};
