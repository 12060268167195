import gql from 'graphql-tag'

const GenderFragment = gql`
  fragment GenderFragment on ConfigurationGender {
    name
    value
  }
`

export default GenderFragment
