import fonts from './fonts'
import breakpoints from './breakpoints'
import palette from './palette'
import textStyles from './textStyles'

const theme = {
  palette,
  fonts,
  breakpoints,
  textStyles,
}

export default theme
