import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { NoNDATemplateProps } from "../templates/NoNDA";
import { useSelector } from "react-redux";
import { selectors } from "../redux";
import { useFooter } from "./useFooter";
import { useHeader } from "./useHeader";

const useNoNDA = ({
  location,
}: {
  location: any;
}): { closingTemplate: NoNDATemplateProps } => {
  const { t } = useTranslation();
  const locale = useSelector(selectors.configuration.locale);
  const header = useHeader({ location });
  const footer = useFooter();
  const user = useSelector(selectors.auth.user);
  const currentAgreement = user?.currentAgreement;
  const isClosed: boolean = useMemo(() => {
    const todayDate = new Date();
    const closingDate = new Date(
      currentAgreement?.event.registrationClosingAt?.replace(/-/g, "/")
    );
    return todayDate?.getTime() > closingDate?.getTime();
  }, [currentAgreement]);

  const date: string = useMemo(() => {
    const date = new Date(
      currentAgreement?.event.registrationClosingAt?.replace(/-/g, "/")
    );

    return date.toLocaleString(locale ?? "", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }, [currentAgreement, locale]);

  const closingTemplate: NoNDATemplateProps = useMemo(
    () => ({
      layout: {
        header,
        footer,
      },
      text: isClosed ? t("closedPageText", "No NDA") : "",
      acknowledgment: isClosed
        ? t("closedPageAcknowledgment", {
            eventName: currentAgreement?.event.name,
            date: date,
          })
        : t("disclosureErrorPageTitle"),
    }),
    [currentAgreement?.event.name, date, footer, header, isClosed, t]
  );

  return {
    closingTemplate,
  };
};

export default useNoNDA;
