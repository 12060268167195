import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ConfigurationLocale } from '../generated/graphql'
import { actions, selectors } from '../redux'

export const useLocaleRedirect = ({ locale }: { locale: string }): boolean => {
  const dispatch = useDispatch()

  const [currentLocale, setCurrentLocale] = useState<ConfigurationLocale | null>()
  const locales = useSelector(selectors.configuration.locales)

  useEffect(() => {
    const currentLocale = locales ? locales.find((item) => item.iso === locale) : null
    setCurrentLocale(currentLocale)

    if (currentLocale) {
      dispatch(actions.configuration.changeLocale({ locale }))
    }
  }, [locales, locale, dispatch])

  return !currentLocale ? true : false
}
