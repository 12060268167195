import styled, { css } from 'styled-components'

export const Content = styled.div`
  max-width: 640px;
  height: 100vh;
  width: 100%;
  margin: auto;
  padding: 3rem 2rem 0 2rem;
  padding: 2rem;
  ${({ theme }) => theme.textStyles.text}

  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Text = styled.p<{ $bold?: boolean }>`
  ${({ theme }) => theme.textStyles.text}
  text-align: center;

  ${({ $bold }) =>
    $bold &&
    css`
      ${({ theme }) => theme.textStyles.textBold}
    `};
`
