import React, { FC } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";

import theme from "../src/theme";

import GlobalStyles from "./theme/global";
import RoutesComponents from "./router/RoutesComponents";
import { app } from "./configuration";

const App: FC = (props) => {
  const { children } = props;

  const { t } = useTranslation();

  const gtmScript = app.GTM_ID && (
    <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${app.GTM_ID}');`}</script>
  );

  const gtmNoScript = app.GTM_ID && (
    <noscript>
      <iframe
        title="gtm"
        src={`https://www.googletagmanager.com/ns.html?id=${process.env.GTM_ID}`}
        height="0"
        width="0"
        style={{
          display: "none",
          visibility: "hidden",
        }}
      />
    </noscript>
  );

  return (
    <React.Fragment>
      <HelmetProvider>
        <GlobalStyles />
        <Helmet>
          <title>{t("siteTitle")}</title>
          <meta name="description" content={t("siteDescription")} />
          {gtmScript}
        </Helmet>
        <StyledThemeProvider theme={theme}>
          {children}
          <RoutesComponents />
        </StyledThemeProvider>
        {gtmNoScript}
      </HelmetProvider>
    </React.Fragment>
  );
};

export default App;
