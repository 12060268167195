import React, { FC } from 'react'
import cx from 'classnames'

import SwitchLocale, { SwitchLocaleProps } from '../SwitchLocale'

import * as SC from './styled'

export type HeaderProps = {
  className?: string
  logout?: {
    label: string
    onClick: () => void
  }
  switchLocale?: SwitchLocaleProps
}

const Header: FC<HeaderProps> = ({ className, logout, switchLocale }) => {
  return (
    <SC.Header className={cx(className)} oneChild={!logout && !switchLocale}>
      <SC.SLogo />
      {logout && <SC.Logout onClick={logout.onClick}>{logout.label}</SC.Logout>}
      {switchLocale && <SwitchLocale {...switchLocale} />}
    </SC.Header>
  )
}

export default Header
