import React, { FC, useRef } from 'react'
import cx from 'classnames'
import ReactSignatureCanvas from 'react-signature-canvas'

import { Icons } from '../Icon/types'

import * as SC from './styled'

export type SignatureCanvasProps = {
  className?: string
  indicationText?: string
  isError?: boolean
  onRef?: (ref: ReactSignatureCanvas | null) => void
}

const SignatureCanvas: FC<SignatureCanvasProps> = ({
  className,
  indicationText,
  isError,
  onRef,
}) => {
  const $signatureCanvas = useRef<ReactSignatureCanvas | null>(null)

  return (
    <SC.Container className={cx(className)}>
      <SC.CanvasContainer isError={isError}>
        <ReactSignatureCanvas
          ref={(ref: any) => {
            $signatureCanvas.current = ref
            onRef?.(ref)
          }}
          minWidth={1.5}
          maxWidth={3}
          backgroundColor="rgb(222,222,222)"
          penColor="#000"
          canvasProps={{
            style: {
              width: '100%',
              height: '200px',
            },
          }}
        />
        {indicationText && <SC.IndicationText>{indicationText}</SC.IndicationText>}
      </SC.CanvasContainer>
      <SC.RefreshIcon
        icon={Icons.refresh}
        onClick={() => {
          $signatureCanvas.current?.clear()
        }}
      />
    </SC.Container>
  )
}

export default SignatureCanvas
