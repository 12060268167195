import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { LoginFormValues } from "../components/LoginForm";
import { LayoutProps } from "../layouts/Layout";
import { actions, selectors } from "../redux";
import { LoginTemplateProps } from "../templates/Login";

import { useFooter } from "./useFooter";
import { usePopinResetPassword } from "./usePopinResetPassword";

export const useLogin = (): { loginTemplate: LoginTemplateProps } => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isPopinOpen, setIsPopinOpen] = useState<boolean>(false);
  const { errors, pending } = useSelector(selectors.auth.login);

  const popinResetPassword = usePopinResetPassword({
    isOpen: isPopinOpen,
    handlePopinClose: () => setIsPopinOpen(false),
  });
  const footer = useFooter();

  const layout: LayoutProps = useMemo(
    () => ({
      footer: footer,
      popins: <>{popinResetPassword}</>,
    }),
    [popinResetPassword, footer]
  );

  const loginTemplate: LoginTemplateProps = useMemo(
    () => ({
      layout,
      loginForm: {
        title: t("loginPageTitleNDA"),
        image: {
          src: "/images/storybook/login.png",
        },
        text:
          "Please enter login and password attached to the email to sign the document.",
        initialValues: {
          login: "",
          password: "",
        },
        fieldProps: {
          login: {
            label: t("loginLabel"),
          },
          password: {
            label: t("passwordLabel"),
          },
          persist_state: {
            label: t("persistStateLabel"),
          },
        },
        submitButton: {
          text: t("logIn"),
          isLoading: pending,
          variant: "secondary",
        },
        errorTexts: {
          required: t("loginValidationRequired"),
        },
        errors,
        forgottenPassword: {
          text: t("passwordForgotten"),
          onClick: () => setIsPopinOpen(true),
        },
        onSubmit: (values: LoginFormValues) => {
          dispatch(
            actions.auth.loginRequest({
              email: values.login,
              password: values.password,
            })
          );
        },
      },
    }),
    [t, layout, dispatch, errors, pending]
  );

  return {
    loginTemplate,
  };
};
