import { createGlobalStyle } from 'styled-components'

import { fontsFaces } from './fonts'

const GlobalStyles = createGlobalStyle`
  ${fontsFaces}
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  html,
  body,
  #root {
    margin: 0;
    height: auto;
  }

  body {
    background: white;
    color: black;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  strong {
    font-family: 'UniversLTCYR-67BoldCond';
    font-weight: bold;
  }
`

export default GlobalStyles
