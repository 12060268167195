import gql from 'graphql-tag'

import SaleEventSlotFragment from './SaleEventSlotFragment'

export default gql`
  fragment GuestFragment on SaleGuest {
    id
    locale
    firstName
    lastName
    fullName
    email
    isVip
    gender
    discountAmount
    createdAt
    updatedAt
    currentGuestEventByRegistrationDate {
      id
      signature
      acceptDisclaimerAt
      slot {
        ...SaleEventSlotFragment
      }
    }
  }
  ${SaleEventSlotFragment}
`
