import gql from 'graphql-tag'

import EmployeeFragment from '../../../fragments/EmployeeFragment'

const employeeByToken = {
  query: gql`
    query employeeByToken {
      employeeByToken {
        ...EmployeeFragment
      }
    }
    ${EmployeeFragment}
  `,
  transformer: (response: any): any => {
    return response.employeeByToken
  },
}

export default employeeByToken
