import React, { FC } from "react";
import PulseLoader from "react-spinners/PulseLoader";

import { Icons } from "../Icon/types";
import palette from "../../theme/palette";
import { LinkProps } from "../Link";

import * as SC from "./styled";

export type ActionButtonProps = {
  className?: string;
  link?: LinkProps;
  type?: "submit" | "button";
  text?: string;
  variant?: "secondary";
  icon?: Icons;
  iconColor?: string;
  iconSide?: "left" | "right";
  disabled?: boolean;
  outlined?: boolean;
  isLoading?: boolean;
  onClick?: (e: any | undefined) => void;
};

const ActionButton: FC<ActionButtonProps> = (props) => {
  const {
    className,
    link,
    children,
    onClick,
    type = "button",
    text,
    icon,
    iconColor,
    iconSide,
    disabled,
    outlined,
    isLoading,
    variant,
  } = props;

  const renderIcon = () =>
    icon && (
      <SC.IconIn
        iconSide={iconSide}
        icon={Icons[icon]}
        color={iconColor ? iconColor : ""}
      />
    );

  const renderButton = () => (
    <SC.Button
      className={!(link?.href || link?.link) ? className : ""}
      onClick={onClick}
      type={type}
      disabled={disabled}
      $outlined={outlined}
      $isLoading={isLoading}
      $secondary={variant}
    >
      {isLoading === true && (
        <PulseLoader color={palette.colors.pureBlack} size={10} />
      )}
      {!isLoading && icon && (!iconSide || iconSide === "left") && renderIcon()}
      {!isLoading ? children || text : ""}
      {!isLoading && icon && iconSide === "right" && renderIcon()}
    </SC.Button>
  );

  return link?.href || link?.link ? (
    <SC.Link
      className={className}
      {...link}
      href={link?.href || link?.link}
      onClick={onClick}
    >
      {renderButton()}
    </SC.Link>
  ) : (
    renderButton()
  );
};

export default ActionButton;
