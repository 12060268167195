import { FormikHelpers } from "formik";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { DisclosureFormValues } from "../components/DisclosureForm";
import { actions, selectors } from "../redux";
import { router, routesPath } from "../router";
import { DisclosureTemplateProps } from "../templates/Disclosure";

import { useFooter } from "./useFooter";
import { useHeader } from "./useHeader";

export const useDisclosure = ({
  location,
  history,
}: {
  location: any;
  history: any;
}): { disclosureTemplate: DisclosureTemplateProps } => {
  const { t } = useTranslation();

  const header = useHeader({ location });
  const footer = useFooter();
  const dispatch = useDispatch();

  const user = useSelector(selectors.auth.user);
  const locale = useSelector(selectors.configuration.locale);
  const { success, pending } = useSelector(selectors.disclosure.setSignature);
  const agreementId = useSelector(selectors.auth.agreementId);

  const agreementById = user?.agreements.find(
    (agreement) => agreement.id === agreementId
  );

  const currentAgreement =
    agreementById && agreementById.disclaimerAcceptedAt
      ? undefined
      : agreementById;

  useEffect(() => {
    if (success) {
      history?.push(router(routesPath.confirmation, { locale: locale ?? "" }));
    }
  }, [success, history, locale]);

  const disclosureTemplate: DisclosureTemplateProps = useMemo(
    () => ({
      layoutProps: {
        header,
        footer,
      },
      title: t("disclosurePageTitle"),
      subTitle: t("disclosurePageSubTitle", { firstName: user?.fullName }),
      text: currentAgreement
        ? (currentAgreement.event.disclaimer as string)
        : "",
      disclosureForm: {
        initialValues: {
          terms: false,
        },
        fieldProps: {
          terms: {
            label: t("disclaimerPageFormTermsLabel"),
          },
        },
        errorTexts: {
          terms: t("disclaimerPageFormTermsError"),
        },
        submitButton: {
          text: t("disclaimerPageFormSubmitButton"),
          isLoading: pending,
        },
        onSubmit: (
          _values: DisclosureFormValues,
          _formikHelpers: FormikHelpers<DisclosureFormValues>,
          signature: string
        ) => {
          dispatch(
            actions.disclosure.setSignatureRequest({
              agreementId: currentAgreement?.id,
              signatureBase64: signature,
            })
          );
        },
      },
    }),
    [header, footer, t, user?.fullName, currentAgreement, pending, dispatch]
  );

  return {
    disclosureTemplate,
  };
};
