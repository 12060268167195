import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";

import { useLocaleRedirect } from "../hooks/useLocaleRedirect";
import NoNDA from "../pages/NoNDA";
import { actions, selectors } from "../redux";
import { router, routesPath } from "../router";
import { usePreload } from "../hooks/usePreload";
import PreloadTemplate from "../templates/Preload";

interface GuestGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }: GuestGuardType) {
  const { locale } = useParams<{ locale: string; token?: string }>();
  const localeRedirect = useLocaleRedirect({ locale });
  const isConnected = useSelector(selectors.auth.isConnected);
  const agreementId = useSelector(selectors.auth.agreementId);
  const user = useSelector(selectors.auth.user);
  const preloadProps = usePreload();
  const currentAgreement = user?.agreements?.find(
    (agreement) => agreement.id === agreementId
  );
  const location = useLocation();
  const dispatch = useDispatch();

  const getAgreementId: () => string | null = useCallback(() => {
    const params = new URLSearchParams(location.search);
    return params.get("agreementId");
  }, [location]);

  useEffect(() => {
    const agreementId = getAgreementId();
    if (agreementId !== null) {
      dispatch(actions.auth.setAgreementId(agreementId));
    }
  }, [dispatch, getAgreementId]);

  const isClosed: boolean = useMemo(() => {
    const todayDate = new Date();
    const closingDate = new Date(
      currentAgreement?.event.registrationClosingAt?.replace(/-/g, "/")
    );

    return todayDate?.getTime() > closingDate?.getTime();
  }, [currentAgreement]);

  if (localeRedirect) {
    return <PreloadTemplate {...preloadProps} />;
  }

  if (isConnected && locale) {
    return <Redirect to={router(routesPath.disclosure, { locale: locale })} />;
  }

  if (isConnected && user && (!currentAgreement || isClosed)) {
    return <NoNDA />;
  }

  return children;
}

export default GuestGuard;
