import styled from "styled-components";

export const Content = styled.div`
  width: 640px;
  margin: auto;
  padding: 3rem 2rem 0 2rem;

  @media screen and (max-width: 640px) {
    width: 100%;
  }
`;

export const Title = styled.h3`
  ${(props) => props.theme.textStyles.titleH1}
  margin-bottom: 2rem;
`;

export const Disclosure = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.colors.silver};
  border-bottom: 1px solid ${({ theme }) => theme.palette.colors.silver};
`;

export const Text = styled.p`
  padding: 2rem 0;
  ${(props) => props.theme.textStyles.text}
`;
