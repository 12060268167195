import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { useLogin } from "../../hooks/useLogin";
import LoginTemplate from "../../templates/Login";

const LoginPage: React.FC<RouteComponentProps> = () => {
  const { loginTemplate } = useLogin();

  return <LoginTemplate {...loginTemplate} />;
};

export default LoginPage;
