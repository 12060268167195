import React, { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type MessageType = {
  label?: string
}

export type FormErrorsProps = {
  className?: string
  errors?: MessageType[] | null
}

const FormErrors: FC<FormErrorsProps> = (props) => {
  const { className, errors } = props

  return (
    <SC.Messages className={cx('FormErrors', className)}>
      {errors && errors?.map((error, index) => <SC.Message key={index}>{error.label}</SC.Message>)}
    </SC.Messages>
  )
}

export default FormErrors
