import styled from 'styled-components'

export const Footer = styled.footer`
  width: 100%;
  height: 90px;
  padding-top: 1rem;
  padding-left: 2rem;
  border-top: 1px solid black;
  margin-top: 4rem;
`

export const Text = styled.p`
  ${(props) => props.theme.textStyles.text}
`
