import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";

import { useLocaleRedirect } from "../hooks/useLocaleRedirect";
import { actions, selectors } from "../redux";
import { router, routesPath } from "../router";
import PreloadTemplate from "../templates/Preload";
import { usePreload } from "../hooks/usePreload";
import NoNDA from "../templates/NoNDA";
import useNoNDA from "../hooks/useNoNDA";

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const { locale } = useParams<{ locale: string; token?: string }>();
  const location = useLocation();
  const dispatch = useDispatch();
  const { closingTemplate } = useNoNDA({ location });
  const localeRedirect = useLocaleRedirect({ locale });
  const isConnected = useSelector(selectors.auth.isConnected || false);
  const agreementId = useSelector(selectors.auth.agreementId);
  const user = useSelector(selectors.auth.user);
  const [loading, setLoading] = useState(true);

  const getAgreementId: () => string | null = useCallback(() => {
    const firstUnacceptedAgreement = user?.agreements.find(
      (agreement) =>
        agreement.disclaimerAcceptedAt === null &&
        new Date(agreement.event.registrationClosingAt) > new Date()
    );
    return firstUnacceptedAgreement ? firstUnacceptedAgreement.id : null;
  }, [user?.agreements]);

  const preloadProps = usePreload();

  useEffect(() => {
    const finalAgreementId = agreementId ? agreementId : getAgreementId();
    if (finalAgreementId !== null) {
      dispatch(actions.auth.setAgreementId(finalAgreementId));
    }

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, [agreementId, dispatch, getAgreementId]);

  const agreementById = user?.agreements.find(
    (agreement) => agreement.id === agreementId
  );

  const currentAgreement =
    agreementById && agreementById.disclaimerAcceptedAt
      ? undefined
      : agreementById;

  const isClosed: boolean = useMemo(() => {
    if (!currentAgreement) {
      return false;
    }
    const todayDate = new Date();
    const closingDate = new Date(
      currentAgreement?.event.registrationClosingAt?.replace(/-/g, "/")
    );
    return todayDate?.getTime() > closingDate?.getTime();
  }, [currentAgreement]);

  useEffect(() => {
    if (currentAgreement !== undefined || (user && !agreementId)) {
      setLoading(false);
    }
  }, [currentAgreement, user, agreementId]);

  if (loading) {
    return <PreloadTemplate {...preloadProps} />; // Show loading spinner or component
  }

  if (localeRedirect) {
    return <PreloadTemplate {...preloadProps} />;
  }

  if (!isConnected && locale) {
    return <Redirect to={router(routesPath.login, { locale: locale })} />;
  }

  if (isConnected && user && (!currentAgreement || isClosed)) {
    return <NoNDA {...closingTemplate} />;
  }

  return <>{children}</>;
}

export default AuthGuard;
