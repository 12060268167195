import gql from 'graphql-tag'

const LocaleFragment = gql`
  fragment LocaleFragment on ConfigurationLocale {
    iso
    label
  }
`

export default LocaleFragment
