import React, { FC } from 'react'

import ChangePasswordForm, { ChangePasswordFormProps } from '../../components/ChangePasswordForm'
import Layout, { LayoutProps } from '../../layouts/Layout'

import * as SC from './styled'

export type ChangePasswordTemplateProps = {
  className?: string
  layoutProps?: LayoutProps
  changePasswordForm: ChangePasswordFormProps
}

const ChangePasswordTemplate: FC<ChangePasswordTemplateProps> = (props) => {
  const { layoutProps, changePasswordForm } = props

  return (
    <Layout {...layoutProps}>
      <SC.Content>
        <ChangePasswordForm {...changePasswordForm} />
      </SC.Content>
    </Layout>
  )
}

export default ChangePasswordTemplate
