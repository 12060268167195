import gql from "graphql-tag";

import { ServiceMutation } from "../../../../redux/api/types/state";

const ndaRefreshToken: ServiceMutation = {
  mutation: gql`
    mutation ndaRefreshToken {
      ndaRefreshToken
    }
  `,
  transformer: (response: string) => {
    return response;
  },
};

export default ndaRefreshToken;
