import { createSlice } from '@reduxjs/toolkit'

import {
  ConfigurationBrand,
  ConfigurationGender,
  ConfigurationLocale,
  ConfigurationWording,
} from '../../generated/graphql'
import type { RootState } from '../store'

import { actionTypes } from './types'

export const name = 'configuration'

export type ConfigurationState = {
  defaultLocale: string
  locale: string | null
  locales: Array<ConfigurationLocale> | null
  wordings: { [key: string]: Array<ConfigurationWording> } | null
  genders: Array<ConfigurationGender> | null
  brands: Array<ConfigurationBrand> | null
}

//
// Initial state
//

const initialState: ConfigurationState = {
  defaultLocale: 'en',
  locale: null,
  locales: [{ iso: 'en', label: 'English' }],
  wordings: null,
  genders: null,
  brands: null,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLocale: (state: ConfigurationState, action: actionTypes.setLocale) => {
      const { locale } = action.payload
      state.locale = locale
    },
    setLocales: (state: ConfigurationState, action: actionTypes.setLocales) => {
      const { locales } = action.payload
      state.locales = locales
    },
    setWordings: (state: ConfigurationState, action: actionTypes.setWordings) => {
      const { wordings } = action.payload
      state.wordings = wordings
    },
    setGenders: (state: ConfigurationState, action: actionTypes.setGenders) => {
      const { genders } = action.payload
      state.genders = genders
    },
    setBrands: (state: ConfigurationState, action: actionTypes.setBrands) => {
      const { brands } = action.payload
      state.brands = brands
    },
    changeLocale: (_: ConfigurationState, __: actionTypes.changeLocale) => undefined,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[name]
const defaultLocale = (state: RootState) => root(state).defaultLocale
const locales = (state: RootState) => root(state).locales
const locale = (state: RootState) => root(state).locale
const labels = (state: RootState) => root(state).wordings
const brands = (state: RootState) => root(state).brands
const genders = (state: RootState) => root(state).genders

export const selectors = {
  defaultLocale,
  locales,
  locale,
  labels,
  brands,
  genders,
}
