import styled, { css } from 'styled-components'

type CommonProps = {
  $isError?: boolean
  $isActive?: boolean
  $isDisabled?: boolean
  $hasErrorMessage?: boolean
}

export const Container = styled.div<CommonProps>`
  display: flex;
  flex-flow: column;

  // disabled
  ${({ $isDisabled }) =>
    $isDisabled === true &&
    css`
      opacity: 0.8;
      &,
      & * {
        cursor: not-allowed;
      }
    `}
`

export const LabelText = styled.span`
  ${({ theme }) => theme.textStyles.text}
  color: ${({ theme: { palette } }) => palette.colors.midGrey}
`

export const Label = styled.label<CommonProps>`
  border: 1px solid ${({ theme }) => theme.palette.main};
  margin-bottom: 2rem;

  ${({ $isError, theme }) =>
    $isError === true &&
    css`
      border-color: ${theme.palette.colors.red};
      margin-bottom: 0.5rem;
    `}

  ${({ $isDisabled, theme }) =>
    $isDisabled === true &&
    css`
      opacity: 0.8;
      background: ${theme.palette.colors.lightGrey};
    `}
`

export const Input = styled.input<CommonProps>`
  ${({ theme }) => theme.textStyles.text}

  color: ${({ theme }) => theme.palette.colors.pureBlack};
  height: 4rem;
  width: 100%;
  border: 0;
  padding: 1.2rem 1rem;
  background: none;

  ${(props) => props.theme.breakpoints.down('sm')} {
    ${({ theme }) => theme.textStyles.input}
  }

  // disabled
  ${({ $isDisabled }) =>
    $isDisabled === true &&
    css`
      pointer-events: none;
      opacity: 0.7;
    `}

  // readonly
    &[readonly] {
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  &[type='password'] {
    letter-spacing: 3px;
  }
`

export const ErrorMessage = styled.p`
  ${({ theme }) => theme.textStyles.text}
  color: ${({ theme }) => theme.palette.colors.red};
  margin: 0.5rem 0;
`
