import styled from 'styled-components'

import Logo from '../Logo'
import breakpoints from '../../theme/breakpoints'

export const Header = styled.header<{ oneChild?: boolean }>`
  display: flex;
  justify-content: ${({ oneChild }) => (oneChild ? 'center' : 'space-between')};
  align-items: center;
  padding: 2rem;
  width: 100%;
  height: 6rem;
  border-bottom: 1px solid black;
`

export const Logout = styled.p`
  cursor: pointer;
  ${(props) => props.theme.textStyles.text};
  margin-left: auto;
  margin-right: 2rem;

  ${breakpoints.up('md')} {
    margin-right: 4rem;
  }
`

export const SLogo = styled(Logo)`
  max-width: 14rem;

  ${breakpoints.up('md')} {
    max-width: 17rem;
  }
`
