import styled, { css } from "styled-components";

import ActionButton from "../../components/ActionButton";

export const Content = styled.div`
  width: 640px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  padding: 3rem 2rem 0 2rem;

  @media screen and (max-width: 640px) {
    width: 100%;
  }
`;

export const Title = styled.h3`
  ${(props) => props.theme.textStyles.titleH1}
  margin-bottom: 2rem;
`;

export const Text = styled.p`
  border-bottom: 1px solid ${({ theme }) => theme.palette.colors.silver};
  padding: 2rem 0;
  ${(props) => props.theme.textStyles.text}
`;

export const Cta = styled(ActionButton)`
  display: block;
  text-transform: uppercase;
`;

export const Button = styled(ActionButton)`
  display: block;
  margin: 3rem auto;
  text-transform: uppercase;
`;

export const Actions = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

export const Message = styled.p<{ $isSuccess?: boolean }>`
  ${(props) => props.theme.textStyles.text};
  color: ${({ theme }) => theme.palette.colors.red};
  text-align: center;
  margin-bottom: 3rem;

  ${({ $isSuccess }) =>
    $isSuccess &&
    css`
      color: ${({ theme }) => theme.palette.colors.green};
    `};
`;
