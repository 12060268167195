import styled, { css } from 'styled-components'

export const Messages = styled.div``

export const Message = styled.p<{ $isError?: boolean }>`
  ${({ theme }) => theme.textStyles.text}
  color: ${({ theme }) => theme.palette.success};

  ${({ $isError }) =>
    $isError &&
    css`
      color: ${({ theme }) => theme.palette.error};
    `}
`
