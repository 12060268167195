import React from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";

import useConfigurePassword from "../../hooks/useConfigurePassword";
import ChangePasswordTemplate from "../../templates/ChangePassword";

const ConfigurePasswordPage: React.FC<RouteComponentProps> = () => {
  const history = useHistory();

  const location = useLocation();

  const params = new URLSearchParams(window.location.search);
  const email = params.get("email");
  const token = params.get("token");

  // Décoder les valeurs
  const decodedEmail = email && decodeURIComponent(email);
  const decodedToken = token && decodeURIComponent(token);

  const { changePasswordTemplate } = useConfigurePassword({
    token: decodedToken,
    email: decodedEmail,
    history,
    location,
  });
  return <ChangePasswordTemplate {...changePasswordTemplate} />;
};

export default ConfigurePasswordPage;
