import gql from "graphql-tag";

import { ServiceMutation } from "../../../../redux/api/types/state";

const ndaForgotPassword: ServiceMutation = {
  mutation: gql`
    mutation ndaForgotPassword($email: String!) {
      ndaForgotPassword(email: $email)
    }
  `,
  transformer: (response: string) => {
    return response;
  },
};

export default ndaForgotPassword;
