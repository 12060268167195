import { useCallback, useEffect } from 'react'
import { Saga } from 'redux-saga'
import { takeEvery } from 'redux-saga/effects'
import { ActionCreator } from 'redux'

import reduxStore from '../redux/store'

const { sagaMiddleware } = reduxStore

export const useSaga = (generatorFn: Saga) => {
  useEffect(() => {
    const task = sagaMiddleware.run(function* fn() {
      yield generatorFn()
    })
    return () => {
      task.cancel()
    }
  }, [generatorFn])
}

export const useSagaEffect = (
  effect: (...args: any[]) => any,
  action: ActionCreator<any>,
  callback: (action: any) => void
) => {
  const callbackFn = useCallback(
    function* fn() {
      yield effect(action, callback)
    },
    [effect, action, callback]
  )
  useSaga(callbackFn)
}

export const useSagaTakeEvery = <T extends ActionCreator<any>>(
  action: T,
  callback: (action: ReturnType<T>) => void
) => {
  useSagaEffect(takeEvery, action, callback)
}
