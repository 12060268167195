import React from 'react'
import { RouteComponentProps, useHistory, useLocation, useParams } from 'react-router-dom'

import useResetPassword from '../../hooks/useResetPassword'
import ChangePasswordTemplate from '../../templates/ChangePassword'

const ResetPasswordPage: React.FC<RouteComponentProps> = () => {
  const { token } = useParams<{ token: string }>()
  const history = useHistory()
  const location = useLocation()
  const { changePasswordTemplate } = useResetPassword({ token, history, location })
  return <ChangePasswordTemplate {...changePasswordTemplate} />
}

export default ResetPasswordPage
