import gql from "graphql-tag";

const authLogout = {
  mutation: gql`
    mutation ndaInvalidateToken {
      ndaInvalidateToken
    }
  `,
  transformer: (response: any) => {
    return response;
  },
};

export default authLogout;
