import styled from "styled-components";

import ActionButton from "../ActionButton";
import FormCheckbox from "../FormCheckbox";
import FormInput from "../FormInput";

export const DisclosureForm = styled.div`
  padding-top: 3rem;
`;

export const Text = styled.div`
  ${({ theme }) => theme.textStyles.text}
  margin-bottom: 3rem;
`;

export const Fields = styled.div`
  margin-bottom: 3rem;
`;
export const SubmitButton = styled(ActionButton)`
  display: block;
  margin: auto;
  margin-top: 2rem;
`;

export const TextField = styled(FormInput)``;
export const CheckboxField = styled(FormCheckbox)``;
export const DateField = styled(FormInput)``;
