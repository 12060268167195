import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Footer, { FooterProps } from '../components/Footer'

export const useFooter = () => {
  const { t } = useTranslation()
  const footer: FooterProps = useMemo(
    () => ({
      text: t('footerText'),
    }),
    [t]
  )

  return <Footer {...footer} />
}
