import { createSelector, createSlice } from "@reduxjs/toolkit";
import jwtDecode, { JwtPayload } from "jwt-decode";

import {
  getServiceProps,
  getServiceReducers,
  ServiceProps,
} from "../../helpers/ReduxHelpers";
import type { RootState } from "../store";
import {
  NdaContractor,
  NdaForgotPasswordMutationVariables,
  NdaResetPasswordMutationVariables,
  QueryNdaCheckResetTokenArgs,
  SaleEmployee,
} from "../../generated/graphql";

import { actionTypes } from "./types";

export type AuthState = {
  user: NdaContractor | null;
  token: string | null;
  agreementId: string | null;
  impersonate?: boolean;
  login: ServiceProps<SaleEmployee, actionTypes.login["payload"]>;
  authLogout: ServiceProps<boolean, undefined>;
  forgotPassword: ServiceProps<boolean, NdaForgotPasswordMutationVariables>;
  resetPassword: ServiceProps<boolean, NdaResetPasswordMutationVariables>;
  checkToken: ServiceProps<boolean, QueryNdaCheckResetTokenArgs>;
  // services
};

//
// Initial state
//

const initialState: AuthState = {
  user: null,
  token: null,
  agreementId: null,
  impersonate: false,
  login: getServiceProps(),
  authLogout: getServiceProps(),
  forgotPassword: getServiceProps(),
  resetPassword: getServiceProps(),
  checkToken: getServiceProps(),
  // Services
};

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    ...getServiceReducers("resetPassword"),
    ...getServiceReducers("forgotPassword"),
    ...getServiceReducers("login"),
    ...getServiceReducers("authLogout"),
    ...getServiceReducers("checkToken"),
    setUser: (state: AuthState, action: actionTypes.setUser) => {
      state.user = { ...action.payload };
    },
    setToken: (state: AuthState, action: actionTypes.setToken) => {
      state.token = action.payload;
    },
    setAgreementId: (state: AuthState, action: actionTypes.setAgreementId) => {
      state.agreementId = action.payload;
    },
    getUser: () => undefined,
    setImpersonate: (state: AuthState, action: actionTypes.setImpersonate) => {
      state.impersonate = action.payload;
    },
    resetAuth: () => initialState,
  },
});

export const { reducer, actions } = slice;

//
// Selectors
//

const root = (state: RootState) => state[slice.name];
const user = (state: RootState) => root(state).user;
const login = (state: RootState) => root(state).login;
const authLogout = (state: RootState) => root(state).authLogout;
const impersonate = (state: RootState) => root(state).impersonate;
const forgotPassword = (state: RootState) => root(state).forgotPassword;
const resetPassword = (state: RootState) => root(state).resetPassword;
const checkToken = (state: RootState) => root(state).checkToken;
const token = (state: RootState) => root(state).token;
const agreementId = (state: RootState) => root(state).agreementId;
const jwt = createSelector([token], (tokenString): JwtPayload | null => {
  return tokenString ? jwtDecode<JwtPayload>(tokenString) : null;
});
const isGuest = createSelector([jwt], (j): boolean => {
  return (j as any)?.data?.userType === "guest";
});

const isConnected = createSelector([token], (t): boolean => {
  return !!t && t !== "";
});

const isExpired = createSelector([jwt], (j): boolean => {
  return !!j && !!j.exp && j.exp * 1000 <= new Date().getTime();
});

export const selectors = {
  user,
  login,
  authLogout,
  impersonate,
  token,
  agreementId,
  forgotPassword,
  resetPassword,
  checkToken,
  jwt,
  isConnected,
  isExpired,
  isGuest,
};
