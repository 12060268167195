import React, { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type FooterProps = {
  className?: string
  text: string
}

const Footer: FC<FooterProps> = (props) => {
  const { className, text } = props

  return (
    <SC.Footer className={cx(className)}>
      <SC.Text>{text}</SC.Text>
    </SC.Footer>
  )
}

export default Footer
