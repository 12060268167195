import React from "react";
import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
  Switch,
  Redirect,
} from "react-router-dom";

import Layout from "../layouts/Layout";
import { RouteType } from "../types/routes";
import Page404 from "../pages/Page404";

import { defaultLayoutRoutes } from "./routes";
import { router, routesPath } from "./index";

const childRoutes = (Layout: React.ElementType, routes: Array<RouteType>) =>
  routes.map(
    ({ component: Component, guard, children, path }, index: number) => {
      const Guard = guard || React.Fragment;

      return children ? (
        children.map((element, index: number) => {
          const ChildrenGuard = element.guard || React.Fragment;
          const ElementComponent = element.component || React.Fragment;

          return (
            <Route
              key={index}
              path={element.path}
              exact
              render={(props: RouteComponentProps) => (
                <Layout>
                  <Guard>
                    <ChildrenGuard>
                      <ElementComponent {...props} />
                    </ChildrenGuard>
                  </Guard>
                </Layout>
              )}
            />
          );
        })
      ) : Component ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Guard>
                <Component {...props} />
              </Guard>
            </Layout>
          )}
        />
      ) : null;
    }
  );

const RoutesComponents = () => (
  <Router>
    <Switch>
      {childRoutes(Layout, defaultLayoutRoutes)}
      <Redirect to={router(routesPath.login, { locale: "en" })} />
      <Route render={() => <Page404 />} />
    </Switch>
  </Router>
);

export default RoutesComponents;
