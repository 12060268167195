import { all, call, put, select, take } from "redux-saga/effects";
import { NotifiableError } from "@bugsnag/js";
import { differenceInMinutes } from "date-fns";

import client, {
  contentfulClient,
  defaultConfig,
  persistedClient,
} from "../../graphql/Client";
import { actions, selectors } from "..";
import bugsnagClient from "../../helpers/BugsnagHelpers";
import refreshToken from "../../graphql/services/user/mutations/ndaRefreshToken";
import ndaMe from "../../graphql/services/user/queries/ndaMe";

import {
  ApiResponse,
  GraphqlQueryVariables,
  QueryType,
  ServiceMutation,
  ServiceQuery,
} from "./types/state";
import { JwtPayload } from "jwt-decode";

export type ApiTransformer = (data: any) => any;

export default class ApiSagas {
  static *getHeaders(checkToken = true): any {
    const headers: Headers = yield select(selectors.api.headers);
    let token = yield select(selectors.auth.token);

    if (checkToken) {
      token = yield call(ApiSagas.getToken);
    }

    return {
      ...headers,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
    };
  }

  static *query(
    service: ServiceQuery,
    variables: GraphqlQueryVariables | null = null
  ) {
    const headers: Headers = yield call(ApiSagas.getHeaders);
    const response: ApiResponse = yield ApiSagas.call(
      client.query,
      service,
      variables,
      headers
    );
    return response;
  }

  static *mutate(
    service: ServiceMutation,
    variables: GraphqlQueryVariables | null = null
  ) {
    const headers: Headers = yield call(ApiSagas.getHeaders);
    const response: ApiResponse = yield ApiSagas.call(
      client.mutate,
      service,
      variables,
      headers
    );
    return response;
  }

  static *persistQuery(
    query: QueryType | any,
    variables: GraphqlQueryVariables | null = null
  ) {
    const headers: Headers = yield call(ApiSagas.getHeaders, false);
    const response: ApiResponse = yield ApiSagas.call(
      persistedClient.query,
      query?.query ? query : { query },
      variables,
      { ...headers, Authorization: undefined } as Headers
    );
    return response;
  }

  static *call(
    method:
      | typeof client.query
      | typeof client.mutate
      | typeof persistedClient.query
      | typeof contentfulClient.query,
    service: ServiceQuery | ServiceMutation,
    variables: GraphqlQueryVariables | null = null,
    headers: Headers
  ) {
    let result: ApiResponse;

    try {
      // @ts-ignore
      result = yield call(method, {
        ...defaultConfig,
        ...service,
        ...(variables && { variables }),
        context: {
          ...service?.context,
          headers: {
            ...service?.context?.headers,
            ...headers,
          },
        },
      });
    } catch (e) {
      console.error(`ApiSagas:`, e, variables);

      if (bugsnagClient) {
        bugsnagClient.addMetadata("graphQL", {
          Variables: variables,
          Config: service,
        });
        bugsnagClient.notify(e as NotifiableError);
      }

      return {
        errors: e,
      };
    }

    if (result.errors) {
      console.error(`ApiSagas:`, result.errors);
    }

    const resultTransformed: ApiTransformer = yield call(
      ApiSagas.transform,
      result,
      service?.transformer
    );

    return resultTransformed;
  }

  static *contentfulQuery(
    service: ServiceQuery,
    variables: GraphqlQueryVariables | null = null
  ) {
    let result: ApiResponse;

    try {
      // @ts-ignore
      result = yield call(contentfulClient.query, {
        ...defaultConfig,
        ...service,
        ...(variables && { variables }),
        context: {
          ...service?.context,
          headers: {
            ...service?.context?.headers,
          },
        },
      });
    } catch (e) {
      console.error(`ApiSagas:`, e, variables);

      if (bugsnagClient) {
        bugsnagClient.addMetadata("graphQL", {
          Variables: variables,
          Config: service,
        });
        bugsnagClient.notify(e as NotifiableError);
      }

      return {
        errors: e,
      };
    }

    if (result.errors) {
      console.error(`ApiSagas:`, result.errors);
    }

    const resultTransformed: ApiTransformer = yield call(
      ApiSagas.transform,
      result,
      service?.transformer
    );

    return resultTransformed;
  }

  static *transform(result: ApiResponse, transformer: ApiTransformer) {
    if (!result.data || !transformer) {
      return result;
    }

    const data: ApiTransformer = yield call(transformer, result.data as any);

    return { ...result, data } as ApiResponse;
  }

  static *checkTokenExpire() {
    const storeToken: string | null = yield select(selectors.auth.token);
    const jwt: JwtPayload | null = yield select(selectors.auth.jwt);
    const expirationDate: number | undefined = jwt?.exp;
    const TOKEN_EXPIRY_THRESHOLD_MINUTES = 5;

    if (!expirationDate || !storeToken) {
      return storeToken;
    }

    const expires = new Date(expirationDate * 1000);

    const diff = differenceInMinutes(expires, new Date());

    console.log(`Token expires in ${diff} minute(s).`);

    if (diff > TOKEN_EXPIRY_THRESHOLD_MINUTES) {
      return storeToken;
    }

    const refreshingToken: boolean = yield select(selectors.api.refreshing);
    console.log(refreshingToken);
    if (refreshingToken) {
      console.log("Waiting for token to refresh...");
      yield take(actions.api.setRefreshing);
      const newToken: string | null = yield select(selectors.auth.token);
      return newToken;
    }

    console.log("Refreshing token...");
    yield put(actions.api.setRefreshing(true));
    const headers: Headers = yield call(ApiSagas.getHeaders, false);
    const result: ApiResponse<typeof refreshToken> = yield call(
      ApiSagas.call,
      client.mutate,
      refreshToken,
      {},
      headers
    );
    console.log(result);
    if (result.errors) {
      console.error("Error refreshing token:", result.errors);
      yield put(actions.auth.resetAuth());
      return null;
    }

    const newToken = result.data.ndaRefreshToken ?? null;

    if (!newToken) {
      console.error("Failed to obtain new token.");
      yield put(actions.auth.resetAuth());
      return null;
    }

    yield put(actions.auth.setToken(newToken));
    yield put(actions.api.setRefreshing(false));
    const user: ApiResponse<typeof ndaMe> = yield call(ApiSagas.query, ndaMe);

    if (!user.errors && user.data) {
      yield put(actions.auth.setUser(user.data));
    }
    return newToken;
  }

  static *getToken() {
    yield call(ApiSagas.checkTokenExpire);
    const token: string | null = yield select(selectors.auth.token);
    return token;
  }

  static *loop() {
    yield all([
      //
    ]);
  }
}
