import React, { FC } from "react";

import Layout, { LayoutProps } from "../../layouts/Layout";

import * as SC from "./styled";

export type ReasonProps = {
  text: string;
};

export type NoNDATemplateProps = {
  layout?: LayoutProps;
  text?: string;
  acknowledgment?: string;
};

const NoNDATemplate: FC<NoNDATemplateProps> = ({
  layout,
  text,
  acknowledgment,
}) => {
  return (
    <Layout {...layout}>
      <SC.Content>
        {text && <SC.Text>{text}</SC.Text>}
        {acknowledgment && <SC.Text>{acknowledgment}</SC.Text>}
        <SC.Text $bold={true}>Balenciaga.</SC.Text>
      </SC.Content>
    </Layout>
  );
};

export default NoNDATemplate;
