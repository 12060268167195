import React from "react";

import useNoNDA from "../../hooks/useNoNDA";
import NoNDATemplate from "../../templates/NoNDA";
import { useLocation } from "react-router";

const NoNDAPage: React.FC = () => {
  const location = useLocation();

  const { closingTemplate } = useNoNDA({ location });
  return <NoNDATemplate {...closingTemplate} />;
};

export default NoNDAPage;
