import React, { FC } from "react";
import cx from "classnames";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";

import { getValidationSchema, renderField } from "../../helpers/FormHelpers";
import { FormFieldConfig, FormFieldProps } from "../../types/form";
import { ActionButtonProps } from "../ActionButton";
import { LinkProps } from "../Link";
import FormErrors, { FormErrorsProps } from "../FormErrors";

import * as SC from "./styled";

export type LoginFormValues = {
  login: string;
  password: string;
};

export type LoginFormProps = {
  className?: string;
  title?: string;
  text?: string;
  image: {
    src: string;
    alt?: string;
  };
  fieldProps?: {
    login: FormFieldProps;
    password: FormFieldProps;
    persist_state: FormFieldProps;
  };
  errorTexts?: {
    login?: string;
    required?: string;
    persist?: string;
  };
  errors?: FormErrorsProps["errors"];
  submitButton: ActionButtonProps;
  forgottenPassword?: LinkProps;
  initialValues: LoginFormValues;
  onSubmit?: (
    values: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>
  ) => void;
};

const LoginForm: FC<LoginFormProps> = (props) => {
  const {
    className,
    title,
    text,
    image,
    errorTexts,
    fieldProps,
    initialValues,
    forgottenPassword,
    submitButton,
    errors,
    onSubmit,
  } = props;

  const fields: FormFieldConfig[] = [
    {
      name: "login",
      Component: SC.TextField,
      validation: Yup.string()
        .email(errorTexts?.login)
        .required(errorTexts?.required),
      required: true,
    },
    {
      name: "password",
      Component: SC.TextField,
      validation: Yup.string().required(errorTexts?.required),
      required: true,
      type: "password",
    },
    {
      name: "persist_state",
      Component: SC.CheckboxField,
    },
  ];

  const validationSchema = getValidationSchema(fields);

  const handleSubmit = (
    values: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>
  ) => {
    onSubmit?.(values, formikHelpers);
  };

  return (
    <SC.LoginForm className={cx("LoginForm", className)}>
      {image && <SC.Image {...image} />}
      {title && <SC.Title>{title}</SC.Title>}
      {text && <SC.Text>{text}</SC.Text>}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(formikProps) => (
          <Form noValidate>
            <SC.Fields>
              {fields.map((field, index) =>
                renderField(field, formikProps, fieldProps, index)
              )}
            </SC.Fields>
            {errors && <FormErrors errors={errors} />}
            {forgottenPassword && (
              <SC.ForgottenPasswordLink {...forgottenPassword} />
            )}
            <SC.SubmitButton {...submitButton} type="submit" />
          </Form>
        )}
      </Formik>
    </SC.LoginForm>
  );
};

export default LoginForm;
