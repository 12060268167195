import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectors } from "../redux";
import { ConfirmationTemplateProps } from "../templates/Confirmation";

import { useFooter } from "./useFooter";
import { useHeader } from "./useHeader";
import { getAuthCookie } from "../helpers/CookieHelpers";

export const useConfirmation = ({
  location,
  history,
}: {
  location: any;
  history: any;
}): { confirmationTemplate: ConfirmationTemplateProps } => {
  const { t } = useTranslation();

  const user = useSelector(selectors.auth.user);
  const header = useHeader({ location });
  const footer = useFooter();
  const agreementId = useSelector(selectors.auth.agreementId);

  const fetchNDA = useMemo(() => {
    return () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      const authToken = getAuthCookie()?.token;

      const url = `${apiUrl}/pdf/nda/agreement/${agreementId}?token=${authToken}`;
      return url;
    };
  }, [agreementId]);

  const confirmationTemplate: ConfirmationTemplateProps = useMemo(
    () => ({
      layoutProps: {
        header,
        footer,
      },
      title: t("confirmationPageTitle"),
      name: user?.fullName,
      text: t("confirmationPageText"),
      // backButton: {
      //   text: t("confirmationPageBackLink"),
      //   link: {
      //     link: router(routesPath.disclosure, { locale: locale ?? "" }),
      //   },
      // },
      cancelButton: {
        text: t("confirmationDisclosureDownloadLink"),
        variant: "secondary",
        link: {
          href: fetchNDA(),
          target: "_blank",
        },
      },
    }),
    [header, footer, t, user?.fullName, fetchNDA]
  );

  return {
    confirmationTemplate,
  };
};
