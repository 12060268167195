import styled, { css } from 'styled-components'

export const Container = styled.div``

export const Checkbox = styled.input<{ $isError?: boolean }>`
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  width: 1.6rem;
  height: 1.6rem;
  border: 1px solid ${({ theme }) => theme.palette.colors.pureBlack};
  border-radius: 0;
  cursor: pointer;
  position: relative;

  ${({ $isError, theme }) =>
    $isError &&
    css`
      border-color: ${theme.palette.colors.red};
    `}

  &::before {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    opacity: 0;
    transition: all 0.3s ease;
    background-color: ${({ theme }) => theme.palette.colors.pureBlack};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:checked::before {
    opacity: 1;
  }
`

export const Label = styled.label`
  display: flex;
  gap: 3rem;
  ${({ theme }) => theme.textStyles.text}
`

export const ErrorMessage = styled.p`
  ${({ theme }) => theme.textStyles.text}
  color: ${({ theme }) => theme.palette.colors.red};
  margin: 0.5rem 0;
`
