import gql from 'graphql-tag'

import GuestFragment from './GuestFragment'
import SaleEmployeeEventFragment from './SaleEmployeeEventFragment'
import SaleEventFragment from './SaleEventFragment'

export default gql`
  fragment EmployeeFragment on SaleEmployee {
    id
    locale
    firstName
    lastName
    fullName
    gender
    email
    isVip
    maxGuests
    createdAt
    updatedAt
    employeeEvents {
      event {
        id
        name
        startAt
        endAt
        registrationClosingAt
      }
    }
    currentEvent {
      ...SaleEventFragment
    }
    currentEmployeeEventByRegistering {
      ...SaleEmployeeEventFragment
    }
    guestsOfEvent {
      ...GuestFragment
    }
  }
  ${GuestFragment}
  ${SaleEventFragment}
  ${SaleEmployeeEventFragment}
`
