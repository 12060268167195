import gql from 'graphql-tag'

import WordingFragment from '../../datas/fragments/WordingFragment'
import LocaleFragment from '../../datas/fragments/LocaleFragment'
import { defaultConfig } from '../../graphql/Client'
import { ServiceQuery } from '../api/types/state'
import GenderFragment from '../../datas/fragments/GenderFragment'
import BrandFragment from '../../datas/fragments/BrandFragment'

import {
  loadWordingsTransformer,
  loadLocalesAndWordingsTransformer,
  loadLocalesTransformer,
} from './transformers'

export const loadLocales: ServiceQuery = {
  ...defaultConfig,
  query: gql`
    query loadLocales($iso: String!) {
      configuration(iso: $iso) {
        locales {
          ...LocaleFragment
        }
      }
    }
    ${LocaleFragment}
  `,
  transformer: (response: any) => {
    return loadLocalesTransformer(response)
  },
}

export const loadWordings: ServiceQuery = {
  ...defaultConfig,
  query: gql`
    query loadWordings($iso: String!) {
      configuration(iso: $iso) {
        wordings {
          ...WordingFragment
        }
      }
    }
    ${WordingFragment}
  `,
  transformer: (response: any) => {
    return loadWordingsTransformer(response)
  },
}

export const loadLocalesAndWordings: ServiceQuery = {
  ...defaultConfig,
  query: gql`
    query loadLocalesAndWordings($iso: String!) {
      configuration(iso: $iso) {
        locales {
          ...LocaleFragment
        }
        wordings {
          ...WordingFragment
        }
      }
    }
    ${LocaleFragment}
    ${WordingFragment}
  `,
  transformer: (response: any) => {
    return loadLocalesAndWordingsTransformer(response)
  },
}

export const loadGenders: ServiceQuery = {
  ...defaultConfig,
  query: gql`
    query loadGenders($iso: String!) {
      configuration(iso: $iso) {
        genders {
          ...GenderFragment
        }
      }
    }
    ${GenderFragment}
  `,
  transformer: (response: any) => {
    return response.configuration
  },
}

export const loadBrands: ServiceQuery = {
  ...defaultConfig,
  query: gql`
    query loadBrands($iso: String!) {
      configuration(iso: $iso) {
        brands {
          ...BrandFragment
        }
      }
    }
    ${BrandFragment}
  `,
  transformer: (response: any) => {
    return response.configuration
  },
}
