import { ActionPattern, all, call, put, select, takeLatest } from 'redux-saga/effects'
import { Saga } from 'redux-saga'

import { actions, selectors } from '..'
import ApiSagas from '../api/sagas'
import { ApiResponse } from '../api/types/state'
import i18nInstance, { resources } from '../../i18n'
import { router, routesPath } from '../../router'
import { ConfigurationLocale } from '../../generated/graphql'

import { loadBrands, loadGenders, loadLocalesAndWordings, loadWordings } from './services'
import { actionTypes } from './types'

export default class ConfigurationSagas {
  static *init(locale?: string) {
    const result: ApiResponse = yield call(ConfigurationSagas.loadLanguages, locale)
    const locales: Array<ConfigurationLocale> | null = yield select(selectors.configuration.locales)
    const hasLocale = locales ? locales.find((item) => item.iso === locale) : false

    if (!hasLocale) {
      const lng: string = yield call(ConfigurationSagas.resolvePreferredLanguage)
      window.location.href = router(routesPath.login, { locale: lng }) // Change by right route
      return
    }

    if (locale) {
      yield put(actions.configuration.setLocale({ locale }))
      yield call(ConfigurationSagas.setWordings, locale, result.data?.wordings)
      yield call(ConfigurationSagas.loadGenders, locale)
      yield call(ConfigurationSagas.loadBrands, locale)
    }

    return false
  }

  static *setWordings(locale: string, wordings?: any) {
    if (wordings) {
      yield put(
        actions.configuration.setWordings({
          wordings: {
            [locale]: { ...(resources as any)?.[locale]?.translation, ...wordings },
          },
        })
      )

      i18nInstance.addResourceBundle(
        locale,
        'translation',
        {
          ...(resources as any)?.[locale]?.translation,
          ...wordings,
        },
        true,
        true
      )
    }

    i18nInstance.changeLanguage(locale)
  }

  static *resolvePreferredLanguage() {
    const defaultLanguage: string = yield select(selectors.configuration.defaultLocale)
    return defaultLanguage
    // const userLanguage = window.navigator.language?.match(/([a-z]{2})-[A-Z]{2}/gm)?.[1]

    // const locales: Locale[] = yield select(selectors.configuration.locales)
    // const localesIso = locales?.map((l: { iso: string }) => l.iso)

    // return localesIso.find((iso) => iso === userLanguage) || defaultLanguage
  }

  static *loadLanguages(locale: string | null = null) {
    let result: ApiResponse | null

    if (locale) {
      result = yield call(ApiSagas.query, loadLocalesAndWordings, {
        iso: locale,
      })
    } else {
      result = null
    }

    if (result?.data?.locales) {
      yield put(
        actions.configuration.setLocales({
          locales: result.data.locales,
        })
      )
    }

    return result
  }

  static *loadWordings(locale: string) {
    const result: ApiResponse = yield call(ApiSagas.query, loadWordings, {
      iso: locale,
    })

    if (!result.errors) {
      yield call(ConfigurationSagas.setWordings, locale, result.data)
    } else {
      console.error('Translations not loaded successfully')
    }
  }

  static *loadGenders(locale: string) {
    const result: ApiResponse = yield call(ApiSagas.query, loadGenders, {
      iso: locale,
    })

    if (!result.errors) {
      yield put(actions.configuration.setGenders(result.data))
    } else {
      console.error('Genders not loaded successfully')
    }
  }

  static *loadBrands(locale: string) {
    const result: ApiResponse = yield call(ApiSagas.query, loadBrands, {
      iso: locale,
    })

    if (!result.errors) {
      yield put(actions.configuration.setBrands(result.data))
    } else {
      console.error('Brands not loaded successfully')
    }
  }

  static *changeLocale({ payload }: actionTypes.changeLocale) {
    yield call(ConfigurationSagas.loadWordings, payload.locale)
  }

  static *loop() {
    yield all([
      takeLatest(actions.configuration.changeLocale as ActionPattern, this.changeLocale as Saga),
    ])
  }
}
