import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import PopinResetPassword, {
  PopinResetPasswordProps,
  ResetPasswordFormValues,
} from "../components/PopinResetPassword";
import { actions, selectors } from "../redux";

export const usePopinResetPassword = ({
  isOpen,
  handlePopinClose,
}: {
  isOpen: boolean;
  handlePopinClose: () => void;
}): React.ReactNode => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, pending } = useSelector(selectors.auth.forgotPassword);

  const popinResetPassword: PopinResetPasswordProps = useMemo(
    () => ({
      isOpen,
      title: t("popinResetPasswordTitle"),
      text: t("popinResetPasswordText"),
      submitButton: {
        isLoading: pending,
        text: t("popinResetPasswordSubmitButton"),
      },
      fieldProps: {
        email: {
          label: t("popinResetPasswordEmailLabel"),
        },
      },
      initialValues: {
        email: "",
      },
      errorTexts: {
        email: t("popinResetPasswordEmailError"),
        required: t("loginValidationRequired"),
      },
      onClose: handlePopinClose,
      onSubmit: (values: ResetPasswordFormValues) => {
        dispatch(actions.auth.forgotPasswordRequest(values));
      },
      submitMessages:
        !pending && data === false
          ? {
              messages: [
                { label: t("popinResetPasswordSubmitError"), isError: true },
              ],
            }
          : !pending && data
          ? { messages: [{ label: t("popinResetPasswordSubmitSuccess") }] }
          : undefined,
    }),
    [isOpen, t, handlePopinClose, dispatch, data, pending]
  );

  return <PopinResetPassword {...popinResetPassword} />;
};
