import fonts from "./fonts";

const textStyles = {
  titleH1: `
    ${fonts.UniversBoldCondensed}
    font-size: 3rem;
    text-transform: uppercase;
    line-height: initial;
  `,
  titleH2: `
    ${fonts.UniversBoldCondensed}
    font-size: 2.4rem;
    line-height: initial;
    text-transform: uppercase;
  `,
  titleH3: `
  ${fonts.UniversBoldCondensed}
  font-size: 1.8rem;
  line-height: initial;
  text-transform: uppercase;
  `,
  text: `
    ${fonts.UniversRoman}
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
  `,
  input: `
    ${fonts.UniversRoman}
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
  `,
  cta: `
    ${fonts.UniversRoman}
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
  `,
  textBold: `
    ${fonts.UniversBoldCondensed}
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.5;
  `,
};

export default textStyles;
