import gql from "graphql-tag";

export default gql`
  fragment AgreementFragment on NdaAgreement {
    id
    event {
      id
      name
      startAt
      endAt
      registrationOpeningAt
      registrationClosingAt
      useDisclaimer
      disclaimer
    }
    disclaimerAcceptedAt
    createdAt
  }
`;
