import React, { FC } from "react";

import LoginForm, { LoginFormProps } from "../../components/LoginForm";
import Layout, { LayoutProps } from "../../layouts/Layout";

import * as SC from "./styled";

export type LoginTemplateProps = {
  className?: string;
  layout?: LayoutProps;
  loginForm: LoginFormProps;
};

const LoginTemplate: FC<LoginTemplateProps> = (props) => {
  const { layout, loginForm } = props;
  return (
    <Layout {...layout} header={undefined}>
      <SC.Wrapper>
        <SC.SLogo />
        <LoginForm {...loginForm} />
      </SC.Wrapper>
    </Layout>
  );
};

export default LoginTemplate;
