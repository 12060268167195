import React, { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'
import SvgLogo from './logos/logo.svg'

export type LogoProps = {
  className?: string
}

const Logo: FC<LogoProps> = (props) => {
  const { className } = props

  return <SC.Logo className={cx('Logo', className)} src={SvgLogo}></SC.Logo>
}

export default Logo
