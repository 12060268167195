import * as apiRedux from "./api/redux";
import * as appRedux from "./app/redux";
import * as authRedux from "./auth/redux";
import * as configurationRedux from "./configuration/redux";
import * as disclosureRedux from "./disclosure/redux";

export const actions = {
  app: appRedux.actions,
  api: apiRedux.actions,
  auth: authRedux.actions,
  configuration: configurationRedux.actions,
  disclosure: disclosureRedux.actions,
};

export const selectors = {
  app: appRedux.selectors,
  api: apiRedux.selectors,
  auth: authRedux.selectors,
  configuration: configurationRedux.selectors,
  disclosure: disclosureRedux.selectors,
};

export const reducers = {
  app: appRedux.reducer,
  api: apiRedux.reducer,
  auth: authRedux.reducer,
  configuration: configurationRedux.reducer,
  disclosure: disclosureRedux.reducer,
};
