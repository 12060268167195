import { css } from 'styled-components'

import { defineFont, Font, FontStyles, FontWeights } from '../utils/FontUtils'

enum FontKeys {
  UniversRoman = 'UniversRoman',
  UniversCondensed = 'UniversCondensed',
  UniversBoldCondensed = 'UniversBoldCondensed',
}

export const declarations: { [key in FontKeys]: Font } = {
  UniversRoman: {
    basename: 'UniversLTCYR-55Roman',
    fontFamily: 'UniversLTCYR-55Roman',
    fontWeight: FontWeights.regular,
    fontStyle: FontStyles.normal,
    fallback: 'Arial, Helvetica, sans-serif',
  },
  UniversCondensed: {
    basename: 'UniversLTCYR-57Condensed',
    fontFamily: 'UniversLTCYR-57Condensed',
    fontStyle: FontStyles.normal,
    fontWeight: FontWeights.regular,
    fallback: 'Arial, Helvetica, sans-serif',
  },
  UniversBoldCondensed: {
    basename: 'UniversLTCYR-67BoldCond',
    fontFamily: 'UniversLTCYR-67BoldCond',
    fontStyle: FontStyles.normal,
    fontWeight: FontWeights.regular,
    fallback: 'Arial, Helvetica, sans-serif',
  },
}

export const fontsFaces: any[] = Object.values(declarations)?.map(
  (declaration) => css`
    @font-face {
      font-family: ${declaration.fontFamily};
      font-style: normal;
      font-display: swap;
      font-weight: ${declaration.fontWeight};
      src: url('/fonts/${declaration.basename}/${declaration.basename}.woff2') format('woff2');
    }
  `
)

const fonts: { [key in FontKeys]: string } = {
  UniversRoman: defineFont(declarations.UniversRoman),
  UniversCondensed: defineFont(declarations.UniversCondensed),
  UniversBoldCondensed: defineFont(declarations.UniversBoldCondensed),
}

export default fonts
