import gql from "graphql-tag";

import { NdaLoginMutation } from "../../../../generated/graphql";

const ndaLogin = {
  mutation: gql`
    mutation ndaLogin($email: String!, $password: String!) {
      ndaLogin(email: $email, password: $password) {
        id
        email
        jwtToken
      }
    }
  `,
  transformer: (response: NdaLoginMutation) => {
    return response;
  },
};

export default ndaLogin;
