import React, { FC } from "react";

import Layout, { LayoutProps } from "../../layouts/Layout";

import * as SC from "./styled";
import DisclosureForm, {
  DisclosureFormProps,
} from "../../components/DisclosureForm";

export type DisclosureTemplateProps = {
  className?: string;
  layoutProps?: LayoutProps;
  title: string;
  subTitle: string;
  text: string;
  disclosureForm: DisclosureFormProps;
};

const DisclosureTemplate: FC<DisclosureTemplateProps> = (props) => {
  const { layoutProps, title, text, disclosureForm, subTitle } = props;

  return (
    <Layout {...layoutProps}>
      <SC.Content>
        <SC.Title>{title}</SC.Title>
        <SC.Disclosure>
          <SC.Text>{subTitle}</SC.Text>
          <SC.Text dangerouslySetInnerHTML={{ __html: text }} />
        </SC.Disclosure>
        <DisclosureForm {...disclosureForm} />
      </SC.Content>
    </Layout>
  );
};

export default DisclosureTemplate;
