import React, { FC } from 'react'

import Layout, { LayoutProps } from '../../layouts/Layout'
import { Icons } from '../../components/Icon/types'

import * as SC from './styled'

export type PreloadTemplateProps = {
  layoutProps?: LayoutProps
  title?: string
  text?: string
}

const PreloadTemplate: FC<PreloadTemplateProps> = ({ layoutProps, title, text }) => {
  return (
    <Layout {...layoutProps}>
      <SC.Content>
        <SC.Title>{title}</SC.Title>
        <SC.Text>{text}</SC.Text>
        <SC.Spin icon={Icons.spinner} />
      </SC.Content>
    </Layout>
  )
}

export default PreloadTemplate
