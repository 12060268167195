import styled, { css } from 'styled-components'

import ActionButton from '../ActionButton'
import FormInput from '../FormInput'
import Icon from '../Icon'
import { Icons } from '../Icon/types'

export const Popin = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  background-color: white;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

export const Close = styled(Icon).attrs({ icon: Icons.close })`
  padding: 0;
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 3.2rem;
  cursor: pointer;
`

export const Content = styled.div`
  max-width: 33.5rem;
`

export const Title = styled.div`
  ${({ theme }) => theme.textStyles.titleH2}
  margin-bottom: 1rem;
  text-align: center;
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.text}
  text-align: center;
  margin-bottom: 2rem;
`

export const SubmitButton = styled(ActionButton)`
  display: block;
  margin: auto;
  margin-top: 3rem;
`

export const Fields = styled.div``

export const EmailField = styled(FormInput)``
