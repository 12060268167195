import React, { FC } from 'react'
import cx from 'classnames'
import * as Yup from 'yup'
import { Form, Formik, FormikHelpers } from 'formik'

import { getValidationSchema, renderField } from '../../helpers/FormHelpers'
import { FormFieldConfig, FormFieldProps } from '../../types/form'
import { ActionButtonProps } from '../ActionButton'
import FormErrors, { FormErrorsProps } from '../FormErrors'

import * as SC from './styled'

export type ChangePasswordFormValues = {
  password: string
}

export type ChangePasswordFormProps = {
  className?: string
  title?: string
  text?: string
  successMessage?: string
  fieldProps?: {
    password: FormFieldProps
  }
  errorTexts?: {
    login?: string
    required?: string
    persist?: string
  }
  errors?: FormErrorsProps['errors']
  submitButton: ActionButtonProps
  initialValues: ChangePasswordFormValues
  onSubmit?: (
    values: ChangePasswordFormValues,
    formikHelpers: FormikHelpers<ChangePasswordFormValues>
  ) => void
}

const ChangePasswordForm: FC<ChangePasswordFormProps> = (props) => {
  const {
    className,
    title,
    text,
    errorTexts,
    fieldProps,
    initialValues,
    submitButton,
    successMessage,
    errors,
    onSubmit,
  } = props

  const fields: FormFieldConfig[] = [
    {
      name: 'password',
      Component: SC.TextField,
      validation: Yup.string().required(errorTexts?.required),
      required: true,
      type: 'password',
    },
  ]

  const validationSchema = getValidationSchema(fields)

  const handleSubmit = (
    values: ChangePasswordFormValues,
    formikHelpers: FormikHelpers<ChangePasswordFormValues>
  ) => {
    onSubmit?.(values, formikHelpers)
  }

  return (
    <SC.ChangePassword className={cx('ChangePassword', className)}>
      {title && <SC.Title>{title}</SC.Title>}
      {text && <SC.Text>{text}</SC.Text>}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(formikProps) => (
          <Form noValidate>
            <SC.Fields>
              {fields.map((field, index) => renderField(field, formikProps, fieldProps, index))}
            </SC.Fields>
            {errors && <FormErrors errors={errors} />}
            {successMessage && <SC.SuccessMessage>{successMessage}</SC.SuccessMessage>}
            <SC.SubmitButton {...submitButton} type="submit" />
          </Form>
        )}
      </Formik>
    </SC.ChangePassword>
  )
}

export default ChangePasswordForm
