import React from 'react'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'

import { useConfirmation } from '../../hooks/useConfirmation'
import ConfirmationTemplate from '../../templates/Confirmation'

const ConfirmationPage: React.FC<RouteComponentProps> = () => {
  const location = useLocation()
  const history = useHistory()
  const { confirmationTemplate } = useConfirmation({ location, history })
  return <ConfirmationTemplate {...confirmationTemplate} />
}

export default ConfirmationPage
