const colors = {
  blue: '#3C49CB',
  red: '#DC0B0B',
  green: '#44bd32',
  orange: '#FF895A',
  pureBlack: '#000',
  pureWhite: '#FFF',
  midGrey: '#808080',
  silver: '#C4C4C4',
  lightGrey: '#EFEFEF',
}

const palette = {
  colors,
  main: colors.pureBlack,
  second: colors.orange,
  error: colors.red,
  success: colors.green,
}

export default palette
