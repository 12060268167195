export const loadLocalesTransformer = (data: any) => data?.configuration?.locales

export const transformWordings = (wordings: any) =>
  wordings?.length
    ? wordings?.reduce(
        (a: any, word: { key: string; translation: string }) => ({
          ...a,
          [word.key]: word.translation,
        }),
        {}
      )
    : null

export const loadWordingsTransformer = (data: any) =>
  transformWordings(data?.configuration?.wordings)

export const loadLocalesAndWordingsTransformer = (data: any) => {
  return {
    locales: loadLocalesTransformer(data),
    wordings: loadWordingsTransformer(data),
  }
}
