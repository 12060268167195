import React, { FC } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type MessageType = {
  label?: string
  isError?: boolean
}

export type FormMessagesProps = {
  className?: string
  messages?: MessageType[] | null
}

const FormMessages: FC<FormMessagesProps> = (props) => {
  const { className, messages } = props

  return (
    <SC.Messages className={cx('FormMessages', className)}>
      {messages &&
        messages?.map((message, index) => (
          <SC.Message $isError={message.isError} key={index}>
            {message.label}
          </SC.Message>
        ))}
    </SC.Messages>
  )
}

export default FormMessages
